import React from 'react'

export default function WorkFastAi() {
  return (
    <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto'>
        <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent font-bold relative z-[1]'>What is Workfast.ai ?</h2>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[20px] md:mt-[40px] sm:w-10/12 mx-auto'>Workfast.ai is a communication and collaboration platform for organising complex work systems and executing with ease which developed to work faster for enterprises.</p>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] sm:w-10/12 mx-auto'>It can be used by any enterprises which has a systemic workflow both in services and product segment. Also it is used by many software development companies , sales and marketing companies , Fintech sectors, Airline sectors , manufacturing sectors , services sector etc.</p>
        <img src='https://dz1x1c630cl14.cloudfront.net/webassets/laptopmobilemock.webp' alt='laptop' className='mt-[20px] md:mt-[40px] md:w-[80%]'/>
    </div>
  )
}