import React from 'react'
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { CgMenuLeft } from "react-icons/cg";

export default function Release1() {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = React.useState(null);
  const [activeId, setActiveId] = React.useState('new-features');
  const [isMore, setIsMore] = React.useState(false);

  // Update active section on scroll
  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  const handleHomeClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 10 ,
        behavior: 'smooth'
      });
    }
  };

  const tocData = [
    { title: 'New Features & Improvements', id: 'new-features' },
    { title: 'Add a Personal List to your Home page', id: 'add-a-personal' },
    { title: 'Customize Automations using task types', id: 'customize-automations' },
    { title: 'Pin task templates to Lists', id: 'pin-task' },
    { title: 'Drag and drop files into a List to create…', id: 'drag-and-drop' },
    { title: 'Bug Fixes', id: 'bug-fixes' },
    { title: 'On-Demand Webinars', id: 'on-demand-webinars' },
  ];

  return (
    <div className='w-full md:w-[70%] lg:w-[77%] xl:w-[80%] ml-auto bg-[#FFF] pb-[50px] md:pb-[100px] overflow-hidden md:overflow-visible'>
      <div className='relative px-[21px] lg:px-[42px] pt-[30px] pb-[18px] md:py-[40px] lg:py-[62px] md:top-[48px]'>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/release_bg.svg" alt='background' className='absolute top-0 z-0 left-0 hidden md:block' />
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/release_mob_bg.svg" alt='background' className='absolute -top-[4px] z-0 left-[4px] block md:hidden w-full' style={{ boxShadow: "1px 0px 2px 0px #00000040" }} />
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg" alt='logo' className='relative z-10 w-auto hidden md:block' />
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/release_mob_logo.svg" alt='logo' className='relative z-10 w-auto block md:hidden' />
        <div className='text-[#000000] font-bold text-[23px] md:text-[47px] md:mt-[30px] lg:mt-[5px] relative z-10'>Release Notes</div>
        <div className='text-[#000000] font-medium text-[7px] md:text-[16px] mt-[5px] relative z-10 md:hidden lg:block'>Release 2.01</div>
      </div>
      <div className='md:flex px-[20px] md:px-0 md:w-[88%] mx-auto gap-[40px] relative mt-[20px] md:mt-[23px]'>
        <div className='flex flex-col gap-[16px] md:gap-[32px] w-full  lg:w-[70%] overflow-y-auto'>
          <div>
            <div className='text-[#2A2E34] text-[20px] md:text-[33px] font-bold flex justify-between '>
              <div>Release 2.01</div>
              <div className='bg-[#00000040] px-[3px] py-[4px] rounded-[4px] relative'>
                <CgMenuLeft className='md:hidden cursor-pointer sticky top-[10px]' onClick={() => { setIsMore(!isMore) }} />
                {isMore && (
                  <div className='p-[12px] border border-[#E6E6E6] bg-[#fff] rounded-[12px] md:hidden absolute right-0 w-[243px]'>
                    {tocData?.map((item,index) => (
                      <div key={index} className={`${activeId === item.id ? "text-[#4A90E2]" : "text-[#7C828D]"} font-medium text-[11px] leading-[23px]`} onClick={() => {setActiveId(item.id); setIsMore(false); handleHomeClick(item.id)}}>{item.title}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <h5 className='text-[#656F7D] text-[11px] font-normal mt-[8px]  md:mt-[11px]'>Last Updated: <span className='font-medium'>1/4/24 at 5:36 pm</span></h5>
          </div>
          <div id='new-features'>
            <div className='text-[#2A2E34] text-[14px] md:text-[26px] font-bold'>New Features & Improvements</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>This week's update brings you new ways to create and automate your tasks, including adding a Personal List to your Home page, using pinned templates or drag and drop to create tasks, and customizing your Automations using task types!</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/new_feature_img.svg" alt='new_feature' className='mt-[22px]' />
          </div>
          <div id='add-a-personal'>
            <div className='text-[#4A90E2] text-[14px] md:text-[26px] font-bold decoration-[#4A90E2] underline'>Add a Personal List to your Home page</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>Never lose track of your own to-dos. Just add a Personal List to your Home page, and create tasks for yourself that are private by default. You can choose to share each task individually, or share your entire Personal List with anyone on your team.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/add_a_personal_img.svg" alt='new_feature' className='mt-[22px]' />
          </div>
          <div id='customize-automations'>
            <div className='text-[#4A90E2] text-[14px] md:text-[26px] font-bold decoration-[#4A90E2] underline'>Customize Automations using task types</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>Task types allow you to turn a task into anything else, like a bug, feature, contact, or opportunity. Now you can create custom Automations for each task type, which means different workflows for different kinds of work. Customization rocks!</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/customize_automation_img.svg" alt='new_feature' className='mt-[22px]' />
          </div>
          <div id='pin-task'>
            <div className='text-[#4A90E2] text-[14px] md:text-[26px] font-bold decoration-[#4A90E2] underline'>Pin task templates to Lists</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>Using task templates just got speedier. You can now pin up to 5 task templates to a List to create detailed tasks in a couple of clicks. You can even set a default template for each List so your team knows exactly which template to use in every scenario.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/pin_task_img.svg" alt='new_feature' className='mt-[22px]' />
          </div>
          <div id='drag-and-drop'>
            <div className='text-[#4A90E2] text-[14px] md:text-[26px] font-bold decoration-[#4A90E2] underline'>Drag and drop files into a List to create tasks</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>Bring your work into Workfast.ai faster than ever. Just drag and drop files into a List, and a task will automatically be created for each file you add. Then you can tag teammates in a comment to start collaborating in seconds.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/drag_and_drop_img.svg" alt='new_feature' className='mt-[22px]' />
          </div>
          <div id='bug-fixes'>
            <div className='text-[#2A2E34] text-[14px] md:text-[26px] font-bold '>Bug Fixes</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'>In our continued effort to make Workfast.ai the most reliable productivity platform, we eliminated a number of key bugs in the platform this week! <span className='text-[#4A90E2] decoration-[#4A90E2] underline'>Here's a quick rundown on some of the top fixes</span></p>
          </div>
          <div id='on-demand-webinars'>
            <div className='text-[#2A2E34] text-[14px] md:text-[26px] font-bold '>On-Demand Webinars</div>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'> • <span className='text-[#4A90E2] decoration-[#4A90E2] underline font-bold'> Workfast.ai for beginners</span> : Learn the basics you need to start using Workfast.ai in 20 minutes.</p>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'> • <span className='text-[#4A90E2] decoration-[#4A90E2] underline font-bold'> Administrator training webinar</span> : Learn how to use our Business Plus & Enterprise Plan features to scale and administrate Workfast.ai long-term! </p>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'> • <span className='text-[#4A90E2] decoration-[#4A90E2] underline font-bold'> Unleash the power of AI</span> : Learn how to use the world's only AI-powered assistant tailored to your role.</p>
            <p className='mt-[11px] text-[#2A2E34] text-[11px] md:text-[13px] font-normal'> • And <span className='text-[#4A90E2] decoration-[#4A90E2] underline font-nromal'> more</span> </p>
          </div>
          <button className='bg-[#FFDD09] rounded-[8px] py-[10px] px-[20px] text-[#1C1800] text-[12px] font-semibold w-[137px]' onClick={() => { navigate('/') }}>Go to Workfast.ai</button>
        </div>
        <aside className='sticky right-0 top-[40px] self-start w-[38%] xl:w-[30%] rounded-[8px] hidden lg:block'>
          <ul className={`mt-[10px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
            {tocData.map((section) => (
              <li key={section.id}>
                <Link
                  activeClass="" // Active link style
                  to={section.id}
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-63}
                  className={`flex gap-[10px] lg:gap-[17px] items-start xl:items-center p-[9px] cursor-pointer ${activeSection === section.id ? "text-[#4A90E2]" : "text-[#7C828D]"}`}
                  onSetActive={handleSetActive}
                >
                  <h3 className='text-[11px] font-medium'>{section.title}</h3>
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </div>
  )
}
