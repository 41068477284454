import React from 'react'

export default function BeginnerGuideProductManagement() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>We all have heard about Product management , project management , product managers , project managers and related Roles in organisations . Many teams in organisation work in silos . It is very much important to coordinate all departments and get the work done according to the goal. So this task is generally named management of business or product management. </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>In this blog we will detaily see what is product management , how it is important in an organisation, what are the roles of product managers , Tools to bring efficiency and productivity in product management etc.</p>
        </div>
    )
}
