import React from 'react'
import Header from './header'
import Sidebar from './sidebar'
import Release1 from './component/release-2.01'

export default function ReleaseNotes() {
  return (
    <div className='w-full h-full bg-[#FFFFFF] relative'>
      <Header/>
      <div className='w-full'>
        <Sidebar/>
        <Release1/>
      </div>
    </div>
  )
}
