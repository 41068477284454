import React from 'react'

export default function ProductManagement() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>What is product management ?</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Product management is all about developing a product effectively and efficiently , which should be marketable and salable in the market. In Layman terms , the developed product should be Design fit , Development fit and Market fit.</p>
        </div>
    )
}
