import React from 'react'

export default function AiInteligence() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[155px] sm:w-[190px] lg:w-[215px]'>AI Intelligence:</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>You can escape the cops not AI. AI is everywhere. The deep question is how useful will it be in Workfast.ai for productivity. You can ask anything in your organisation.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>For example, a manager want to know about who is on leave today in his team or who are the top performers of this quarter or who completed the week tasks fully last week. AI intelligence will give results in no time to the manager.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>You are setting out for a meeting, and you want to know about the meeting personality fully, just ask you shall get it. one can see pending approval from his side and many more.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>AI intelligence is a gift for all who really need it. The purpose of AI is no one should left ignorant in an organisation.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>AI will sit on top of your company data and serve you when needed. It can also tell if an engineer or designer is overloaded with work and even suggest other available team members for the same work.</p>
        </div>
    )
}
