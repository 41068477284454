import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

export default function Banner() {
  const navigate = useNavigate();
  return (
    <div className='mt-[20px] sm:mt-[60px]'>
        <div className='px-[24px] py-[11px] w-[240px] mx-auto bg-[#F1F1F9] rounded-[120px] text-[#514B81] text-[14px] font-normal text-center'>The #1 Todoist Alternative</div>
        <div className='mt-[20px] text-[#0A0800] text-[26px] sm:text-[40px] lg:text-[50px] xl:text-[62px] font-bold text-center w-10/12 mx-auto'>
            <h2>Bring in team focus and productivity in your workplace</h2>
        </div>
        <h3 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal text-center w-10/12 lg:w-[55%] mx-auto mt-[20px] lg:mt-[40px]'>Focus driven environment brings in more creativity than segregated, divided, unfocused workspace</h3>
        <div className='bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[12px] w-[200px] sm:w-[300px] lg:w-[420px] h-[40px] sm:h-[50px] lg:h-[66px] mx-auto mt-[20px] lg:mt-[40px] flex items-center justify-center sm:gap-[8px] text-[#2A2200] text-[16px] sm:text-[22px] lg:text-[28px] font-semibold cursor-pointer' onClick={() => {navigate('/signup')}}>
            <h4>Sign-up for free</h4><IoIosArrowRoundForward/>
        </div>
    </div>
  )
}
