import React from 'react'

export default function Banner() {
  
  const handleHomeClick = () => {
    const element = document.getElementById('principles');
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className='flex flex-col justify-center items-center bg-white'>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/handshake_with_confetti.svg" alt='bannerImg' />
      <h3 className='text-[#000] text-[32px] sm:text-[52px] lg:text-[72px] font-bold'>Workfast.ai</h3>
      <h3 className='text-[#000] text-[32px] sm:text-[52px] lg:text-[72px] font-bold'>Partnership Program</h3>
      <h4 className='text-[#8E8E93] text-[14px] sm:text-[20px] lg:text-[24px] font-normal text-center w-10/12 lg:w-8/12 mx-auto'>This program aims at providing greater opportunities for experienced individuals and consultants for earning fortunes by leveraging their networks and connections </h4>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/mouse_arrow.svg" alt='arrow' className='mt-[20px] sm:mt-[40px] lg:mt-[60px] cursor-pointer up-arrow ' onClick={() =>{handleHomeClick()}}/>
      {/* <div className='bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[12px] w-[200px] sm:w-[300px] lg:w-[420px] h-[50px] lg:h-[66px] mx-auto mt-[20px] sm:mt-[40px] lg:mt-[60px] flex items-center justify-center sm:gap-[8px] text-[#2A2200] text-[18px] sm:text-[22px] lg:text-[28px] font-semibold cursor-pointer' onClick={() =>{handleHomeClick()}}>
        <h4>Learn More</h4><IoIosArrowRoundForward />
      </div> */}
    </div>
  )
}
