import React from 'react'
import { FaCaretRight } from "react-icons/fa";

export default function Sidebar() {
    const [is2024Release, setIs2024Release] = React.useState(false);
    const [isQ1, setIsQ1] = React.useState(false);
    const [releaseVerison, setReleaseVersion] = React.useState('Release 2.01');
    

    return (
        <div className='fixed top-[48px] w-[30%] lg:w-[23%] xl:w-[20%] px-[8px] py-[18px] bg-[#F7F8F9] border-l border-[#E8EAED] h-screen z-0 hidden md:flex flex-col'>
            <div className='py-[8px] px-[12px] flex gap-[6px] items-center text-[14px] font-normal cursor-pointer' onClick={() => { setIs2024Release(!is2024Release) }}>
                <FaCaretRight className={`text-[#7C828D] transition-all duration-500 ${is2024Release ? "" : "rotate-90"}`} />
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/release_icon.svg" alt='release' />
                <div className='text-[#24242A]'>2024 Release Notes</div>
            </div>
            <div className={`overflow-hidden transition-all duration-500 ${is2024Release ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
                <div className='py-[8px] px-[24px] flex gap-[30px] items-center font-normal text-[14px] cursor-pointer' onClick={() => { setIsQ1(!isQ1);}}>
                    <FaCaretRight className={`text-[#7C828D] transition-all duration-500 ${isQ1 ? "" : "rotate-90"}`} />
                    <div className='text-[#24242A]'>Q1 2024</div>
                </div>
                <div className={`overflow-hidden transition-all duration-500 ${isQ1 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
                    <div className={`py-[8px] px-[36px] flex gap-[30px] items-center font-normal text-[14px] cursor-pointer rounded-[4px] ${releaseVerison === "Release 2.01" ? "bg-[#e3e4e4]" : ""}`} onClick={() => { setReleaseVersion('Release 2.01') }}>
                        <FaCaretRight className={`text-[#7C828D]`} />
                        <div className='text-[#24242A]'>Release 2.01</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
