import React from 'react'
import { HiDownload } from "react-icons/hi";

export default function Header() {
  return (
    <div className='px-[16px] py-[10px] hidden md:flex justify-between w-full items-center fixed bg-[#FFF] z-20'>
        <div className='flex items-center gap-[12px] text-[#2A2E34] font-normal text-[14px]'>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/release_icon.svg" alt='release'/>
            <div>2024 Release Notes</div>
            <div>/</div>
            <div>Q1 2024</div>
            <div>/</div>
            <div className='font-medium'>Release 2.01</div>
        </div>
        <div className='flex items-center gap-[12px]'>
            <div className='text-[#2A2E34] text-[14px] font-medium flex gap-[8px] items-center'>
                <HiDownload/>
                <div>Download</div>
            </div>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg" alt='logo'/>
        </div>
    </div>
  )
}
