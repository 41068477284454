import React from 'react'

export default function WorkfastAi() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>What is Workfast.ai ?</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Workfast.ai is a communication and collaboration platform for organising complex work systems and executing with ease which developed to work faster for enterprises. </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>It can be used by any enterprises which has a systemic workflow both in services and product segment. Also it is used by many software development companies , sales and marketing companies , Fintech sectors, Airline sectors , manufacturing sectors , services sector etc.</p>
        </div>
    )
}