import React from 'react'

export default function ContactUs() {
    const handleEmailClick = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@workfast.ai');
      };
    return (
        <div className='flex flex-col w-full h-screen justify-center items-center bg-[#010306] relative overflow-hidden p-[10px] md:p-[0px]'>
            <img src='https://dz1x1c630cl14.cloudfront.net/webassets/yellowglow.png' alt='yellowGlow' className='absolute top-[0px] z-[0] ' />
            <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
            <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <div className='shooting-stars1'></div>
            <div className='shooting-stars'></div>
            {/* <h1 className='DarkerGrostesqueSemibold text-[30px] sm:text-[40px] lg:text-[80px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent text-center z-[1]'>Help</h1> */}
            <img src='https://dz1x1c630cl14.cloudfront.net/webassets/one_ai-Logo1.svg' alt='logo' className='relative z-[10] w-[200px] lg:w-[300px]'/>
            <h3 className='text-[#E4E8EB] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[30px] lg:mt-[50px] xl:w-[80%] text-center relative z-[10]'>For contact, reach us at <span className='text-[#FDD220] underline cursor-pointer' onClick={() => {handleEmailClick()}}>hello@workfast.ai</span>.</h3>
            <h3 className='text-[#E4E8EB] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[16px] w-[80%] text-center'>Our team will respond and do the needful.</h3>

        </div>
    )
}
//   return (
//     <div className='w-full h-screen relative p-10'>
//         <img src="https://dz1x1c630cl14.cloudfront.net/webassets/Glowing.svg" alt='glow' className='fixed w-full h-full top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-0'/> 
//         <h1 className='DarkerGrostesqueSemibold text-[30px] sm:text-[40px] lg:text-[80px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent text-center z-[1]'>Contact Us</h1>
//         <h3 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[30px]'>Last updated on 01-03-2024 15:32:22</h3>
//         <h3 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-semibold mt-[30px] z-10 relative'>You may contact us using the information below:</h3>
//         <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal z-10 relative'><span className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FDD320] font-medium'>Merchant Legal entity name: </span> One.Ai</h4>
//         <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal z-10 relative'><span className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FDD320] font-medium'> Registered Address: </span>PLOT No. 70, Gomathi Nagar, Velachery, Chennai,, Chennai, Tamil Nadu, PIN: 600042</h4>
//         <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal z-10 relative'><span className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FDD320] font-medium'> Operational Address: </span>PLOT No. 70, Gomathi Nagar, Velachery, Chennai,, Chennai, Tamil Nadu, PIN: 600042</h4>
//         <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal z-10 relative'><span className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FDD320] font-medium'> Telephone No: </span> 8925910212</h4>
//         <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal z-10 relative mb-[100px]'><span className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FDD320] font-medium'> E-Mail ID: </span>payments@workfast.ai</h4>
//     </div>
//   )
// }
