import React from 'react'

export default function UniversalSearch() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[180px] sm:w-[215px] lg:w-[250px]'>Universal search :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Finding files, docs, images, videos inside a platform is a humongous task. With the universal search option, you can search with tags or just type the specific message easily and find out. It actually saves time and can retrieve valuable information at right time. </p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/universal-search-img.svg" alt=''/>
        </div>
    )
}
