import React from 'react'

export default function AiIntelligence() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[24px] lg:text-[30px] font-semibold relative z-[1] w-[155px] md:w-[180px] lg:w-[225px]'>AI intelligence :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Now AI is the buzz word for any development. Software tools without AI stands behind the race. Only question arises to me is, “How great we can use AI for productivity and management”. Actually it is a wonderful combination.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai rightly captures the essence of the combination. For example many approvals got missed in the chat, as a CEO of a 250 member company its difficult to know who is on leave, finding who performed well in the last quarter. If this is difficult and time consuming, just ask to Workfast.ai, it will give you in a second of what you want.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Not only this, ask anything related to your company eg, Any reports, meetings, Meeting summary etc. I just mentioned only few use cases to my knowledge and there are infinite. One purpose Workfast.ai caters is, everyone inside the organisation should be able to know everything in a fraction of second. When time consumption is less, one can use their time well.</p>
        </div>
    )
}
