import React from 'react'

export default function ProductVsProject () {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>Product Vs Project management :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Product management is a term suits for any company that develop product. Since the boom of software market , product management as a term typically used in product software making companies.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Whereas in Services software companies , they generally term as Project management because , services usually deal with customers for doing services like website, app development etc.</p>
        </div>
    )
}
