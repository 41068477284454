import React from 'react'

export default function RolesInProductManagement() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>What are the roles in product management?</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Product managers are usually “Generalists” who takes full responsibility for managing product starting from Design , development , testing and reaching product to the market . Product managers are usually “Generalists” who takes full responsibility for managing product starting from Design , development , testing and reaching product to the market.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>A product team without a product manager is like an orchestra without a conductor.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Most notably, product managers link the development team on the one side with marketing, sales, and customer success teams on the other side, </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>while ensuring the process is aligned with the business vision.</p>
        </div>
    )
}
