import React from "react";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import paymentStore from "../../utils/store/paymentStore";
import axios from "axios";
import API from "../../utils/API";
import helperUtils from "../../utils/helperUtils";
import LazyLottie from "react-lottie";
import cardLoading from "../../images/cardLoading.json";
import loadMore from "../../images/loadMore.json";

export default function Payment({ priceIndex }) {
  const workspaceDetails = paymentStore();

  const [showAll, setShowAll] = React.useState(false);
  const [organisationEmail, setOrganisationEmail] = React.useState("");
  const [organisationEmailError, setOrganisationEmailError] =
    React.useState(false);
  const [organisationName, setOrganisationName] = React.useState("");
  const [organisationNameError, setOrganisationNameError] =
    React.useState(false);
  const [membersCount, setMemberCount] = React.useState(1);
  const [membersCountError, setMemberCountError] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [country, setCountry] = React.useState("India");
  const [streetName, setStreetName] = React.useState("");
  const [streetNameError, setStreetNameError] = React.useState(false);
  const [cityName, setCityName] = React.useState("");
  const [cityNameError, setCityNameError] = React.useState(false);
  const [stateName, setStateName] = React.useState("");
  const [stateNameError, setStateNameError] = React.useState(false);
  const [postalCode, setPotalCode] = React.useState("");
  const [postalCodeError, setPotalCodeError] = React.useState(false);
  const [GSTIN, setGSTIN] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("Pay By Card");
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardNumberError, setCardNumberError] = React.useState(false);
  const [expiry, setExpiry] = React.useState("");
  const [expiryError, setExpiryError] = React.useState(false);
  const [cvvNum, setCvvNum] = React.useState("");
  const [cvvNumError, setCvvNumError] = React.useState(false);
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountNumberError, setAccountNumberError] = React.useState(false);
  const [ifscCode, setIfscCode] = React.useState("");
  const [ifscCodeError, setIfscCodeError] = React.useState(false);
  const [accountHolderName, setAccountHolderName] = React.useState("");
  const [accountHolderNameError, setAccountHolderNameError] =
    React.useState(false);
  const [submit, setSumbit] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState("USD");
  const [gateway, setGateway] = React.useState("RAZORPAY");
  const [startPayment, setStartPayment] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [afterLoading, setAfterLoading] = React.useState(false);
  const [specialData, setSpecialData] = React.useState(true);
  const navigate = useNavigate();

  const razorpayKey = process.env.REACT_APP_RAZOR_PAY_KEY;

  const handleChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleSeeMore = () => {
    setShowAll(true);
  };

  React.useEffect(() => {
    let total;

    total =
      specialData?.plan?.filter((e) => e?.currency === selectedCurrency)[0]
        ?.monthly * membersCount ?? 1;

    setTotalAmount(total);
  }, [membersCount, specialData, selectedCurrency]);

  const index1 = [
    { title: "Channels" },
    { title: "Chats" },
    { title: "Projects and Task management" },
    { title: "Artificial intelligence" },
    { title: "People and work teams" },
    { title: "Goals" },
    { title: "Integration with basic 5 softwares" },
    { title: "Organisation chart" },
    { title: "Users upto 50" },
    { title: "Suitable for small teams" },
  ];

  // const handleEmailChange = (e) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/;
  //   if (emailRegex.test(e.target.value)) {
  //     setOrganisationEmail(e.target.value);
  //     setOrganisationEmailError(false);
  //   }
  //   if (e.target.value === "") {
  //     setOrganisationEmailError(false);
  //   }
  // };

  // const handleNameChange = (e) => {
  //   if (/^[\x20-\x7E]*$/.test(e.target.value)) {
  //     setOrganisationName(e.target.value);
  //     setOrganisationNameError(false);
  //   }
  //   if (e.target.value === "") {
  //     setOrganisationNameError(false);
  //   }
  // };

  const handleMemberCountChange = (e) => {
    const memberCount = e.target.value.replace(/^0+/, "");
    setMemberCount(memberCount?.slice(0, 4));
    if (e.target.value === "" || memberCount !== "") {
      setMemberCountError(false);
    }
    if (memberCount?.length > 4 || memberCount == 0) {
      setMemberCountError(true);
    }
  };

  const handleStreetName = (e) => {
    if (/^[\x20-\x7E]*$/.test(e.target.value)) {
      setStreetName(e.target.value?.trimStart()?.replace(/ +(?= )/g, ""));
    }
    if (e.target.value === "" || e.target.value !== "") {
      setStreetNameError(false);
    }
  };

  const handleCityName = (e) => {
    if (/^[\x20-\x7E]*$/.test(e.target.value)) {
      setCityName(e.target.value?.trimStart()?.replace(/ +(?= )/g, ""));
    }
    if (e.target.value === "" || e.target.value !== "") {
      setCityNameError(false);
    }
  };

  const handleStateName = (e) => {
    if (/^[\x20-\x7E]*$/.test(e.target.value)) {
      setStateName(e.target.value?.trimStart()?.replace(/ +(?= )/g, ""));
    }
    if (e.target.value === "" || e.target.value !== "") {
      setStateNameError(false);
    }
  };

  const handlePostalCodeChange = (e) => {
    let postalCode = e.target.value.replace(/\D/g, "");
    setPotalCode(postalCode?.trimStart()?.replace(/ +(?= )/g, ""));
    if (e.target.value === "" || postalCode !== "") {
      setPotalCodeError(false);
    }
  };

  const handleGSTINChange = (e) => {
    const value = e.target.value
      .toUpperCase()
      ?.trimStart()
      ?.replace(/ +(?= )/g, "");
    const sanitizedValue = value.replace(/[^A-Z0-9]/g, "");
    setGSTIN(sanitizedValue);
  };

  const handleSubmit = () => {
    if (organisationEmail === "") {
      setOrganisationEmailError(true);
    } else {
      setOrganisationEmailError(false);
    }
    if (organisationName === "") {
      setOrganisationNameError(true);
    } else {
      setOrganisationNameError(false);
    }
    if (membersCount === "") {
      setMemberCountError(true);
    } else {
      setMemberCountError(false);
    }
    if (streetName === "") {
      setStreetNameError(true);
    } else {
      setStreetNameError(false);
    }
    if (cityName === "") {
      setCityNameError(true);
    } else {
      setCityNameError(false);
    }
    if (stateName === "") {
      setStateNameError(true);
    } else {
      setStateNameError(false);
    }
    if (postalCode === "") {
      setPotalCodeError(true);
    } else {
      setPotalCodeError(false);
    }
    if (accountNumber === "") {
      setAccountNumberError(true);
    } else {
      setAccountNumberError(false);
    }
    if (ifscCode === "") {
      setIfscCodeError(true);
    } else {
      setIfscCodeError(false);
    }
    if (accountHolderName === "") {
      setAccountHolderNameError(true);
    } else {
      setAccountHolderNameError(false);
    }
    if (
      organisationEmail !== "" &&
      organisationName !== "" &&
      membersCount !== "" &&
      streetName !== "" &&
      cityName !== "" &&
      stateName !== "" &&
      postalCode !== ""
    ) {
      setSumbit(true);
    } else {
      setSumbit(false);
    }
  };

  const getSpecialOffer = async () => {
    try {
      const result = await axios.post(API.GET_SPECIAL_OFFER, {}, {});
      setSpecialData(result.data?.data);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getPayOrder = async (infoId) => {
    if (infoId !== null) {
      try {
        const result = await axios.post(
          API.SPECIAL_INITIATE_PAYMENT,
          {
            paymentGateway: "RAZORPAY",
            estimateDurationType: "MONTH",
            currency: selectedCurrency ?? "USD",
            infoId: infoId,
          },
          {}
        );

        return result;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const paymentSuccess = async (data, userToken) => {
    try {
      const result = await axios.post(API.PAYMENT_SUCCESS, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setAfterLoading(false);
      navigate("/payment-signup");
    } catch (error) {
      console.log(error);
      setAfterLoading(false);
      navigate("/payment-signup");
    }
  };

  const updateOrganizationInfo = async () => {
    try {
      setStartPayment(false);
      let sendData = {};

      if (organisationName !== "")
        sendData["organizationName"] = organisationName;
      if (membersCount !== "") sendData["membersCount"] = membersCount;
      if (country !== "") sendData["country"] = country;
      if (streetName !== "") sendData["address"] = streetName;
      if (cityName !== "") sendData["city"] = cityName;
      if (stateName !== "") sendData["state"] = stateName;
      if (postalCode !== "") sendData["postalCode"] = postalCode;
      if (GSTIN !== "") sendData["gstinId"] = GSTIN;

      const { data } = await axios.post(
        API.UPDATE_ORGANIZATION_INFO,
        sendData,
        {}
      );

      console.log(data);
      if (data.data.infoId) {
        displayRazorpay(data.data.infoId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanyUrl = async (companyUrl, userId, workSpaceId) => {
    try {
      const sendCondition = {
        userId: userId,
        companyId: workSpaceId,
        companyUrl: companyUrl,
      };
      await axios.post(`${API.UPDATECOMPANYURL}`, sendCondition);
    } catch (err) {
      //console.log(err)
    }
  };

  const handleContinueProfile = async (paymentData) => {
    setStartPayment(false);
    setAfterLoading(true);
    const formData = new FormData();
    if (workspaceDetails.userImage) {
      formData.append("userImage", workspaceDetails.userImage);
    }
    formData.append("workspaceName", workspaceDetails.workspaceName);
    formData.append("userEmail", workspaceDetails.userEmail);
    formData.append("userName", workspaceDetails.userName);
    if (workspaceDetails.designation) {
      formData.append("designation", workspaceDetails.designation);
    }
    formData.append("signupType", workspaceDetails.signupType);
    formData.append("selectedPlan", workspaceDetails.selectedPlan);
    try {
      const response = await axios.post(`${API.CREATEWORKSPACE}`, formData, {
        headers: {
          Platform: "web",
          "Device-Id": workspaceDetails.deviceId,
        },
      });

      if (response.data.statusCode === 200) {
        try {
          const sendCondition = { email: workspaceDetails.userEmail };
          await axios.post(`${API.CHANGEJOINSTATUS}`, sendCondition);
        } catch (err) {}
        paymentStore.setState({ workspaceDetails: response.data.data });

        const userResponse = response.data.data.userDetails;
        const workspaceResponse = response.data.data.workspaceDetails;

        paymentStore.setState({ userDetails: userResponse });
        await updateCompanyUrl(
          workspaceResponse.workspaceUrl,
          userResponse.userId,
          workspaceResponse.workspaceId
        );
        paymentStore.setState({ paymentStatus: "SUCCESS" });
        await paymentSuccess(paymentData, userResponse.userToken);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const displayRazorpay = async (infoId) => {
    setLoading(true);
    setStartPayment(true);
    setGateway("");
    const res = await helperUtils.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let orderData = await getPayOrder(infoId);

    if (!orderData) {
      alert("Server error. Are you online?");
      return;
    }

    const amount = orderData?.data?.amount;
    const currency = orderData?.data?.currency;
    const options = {
      key: razorpayKey,
      amount: amount,
      currency: currency,
      name: workspaceDetails?.workspaceName,
      description: "Test Transaction",
      // image: { logo },
      order_id: orderData?.data?.id,
      handler: async function (response) {
        const paymentData = {
          infoId: infoId,
          orderCreationId: orderData?.data?.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          paymentGateway: gateway?.toUpperCase(),
        };

        handleContinueProfile(paymentData);
      },
      // prefill: {
      //   name: "Soumya Dey",
      //   email: "SoumyaDey@example.com",
      //   contact: "9999999999",
      // },
      notes: {
        infoId: infoId,
        userEmail: workspaceDetails.userEmail,
        userName: workspaceDetails.userName,
        workspaceName: workspaceDetails?.workspaceName,
      },
      theme: {
        color: "#61dafb",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "Pay via Card",
              instruments: [
                {
                  method: "card",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },
      modal: {
        ondismiss: function () {
          console.log("Razorpay payment popup closed by user.");
          setStartPayment(true);
          setSumbit(false);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    setLoading(false);
    paymentObject.open();
  };

  React.useEffect(() => {
    if (
      paymentMethod === "Pay By Bank" &&
      submit &&
      !organisationNameError &&
      !membersCountError &&
      !streetNameError &&
      !cityNameError &&
      !postalCodeError &&
      !accountNumberError &&
      !ifscCodeError &&
      !accountHolderNameError
    ) {
      setSuccess(true);
    } else if (
      paymentMethod === "Pay By Card" &&
      submit &&
      !organisationNameError &&
      !membersCountError &&
      !streetNameError &&
      !cityNameError &&
      !postalCodeError &&
      !cardNumberError &&
      !expiryError &&
      !cvvNumError
    ) {
      console.log("object");
      setSuccess(true);
    } else {
      setSuccess(false);
    }

    setOrganisationEmail(workspaceDetails.userEmail);
    setOrganisationName(workspaceDetails.workspaceName);

    if (
      workspaceDetails.userEmail == "" ||
      workspaceDetails.workspaceName == "" ||
      workspaceDetails.userName == ""
    ) {
      navigate("/payment-signup");
    }
  }, []);

  React.useEffect(() => {
    getSpecialOffer();
  }, []);

  React.useEffect(() => {
    getSpecialOffer();
    if (submit) {
      updateOrganizationInfo();
    }
  }, [submit]);

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [success]);

  return (
    <div className="w-full h-full bg-[#FFFFFF]">
      {!afterLoading ? (
        <>
          <div
            className="w-full bg-[#ffffff] py-[15px] px-[30px] relative"
            style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
          >
            <img
              src="https://dz1x1c630cl14.cloudfront.net/webassets/payment_logo.svg"
              alt="logo"
            />
            {/* <h3 className="text-[#000000] text-[20px] nunito-semibold absolute top-[50%] -translate-x-1/2 left-[50%] -translate-y-1/2">
          Upgrade <span className="nunito-bold">workfast.ai workSpace</span> to
          pro{" "}
        </h3> */}
          </div>
          {!loading ? (
            <div className="w-10/12 mx-auto py-[32px] grid grid-cols-3">
              <div className="col-span-2">
                <div className="ml-[150px] w-7/12 flex flex-col gap-[27px]">
                  <div>
                    <h3 className="text-[#000000] text-[20px] nunito-semibold">
                      1. Workspace info
                    </h3>
                    <div className="py-[27px] border-b-[1px] border-[#D9D9D9] flex flex-col gap-[17px] text-[#000000] nunito-normal text-[16px]">
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>Workspace Email*</h3>
                        <input
                          type="text"
                          value={organisationEmail}
                          placeholder="Enter your Workspace email"
                          className={`px-[11px] py-[12px] max-w-full border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            organisationEmailError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          disabled={true}
                          // onChange={(e) => {
                          //   handleEmailChange(e);
                          // }}
                        />
                      </div>
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>Workspace name*</h3>
                        <input
                          type="text"
                          value={organisationName}
                          placeholder="Enter your company name"
                          className={`px-[11px] py-[12px] max-w-full border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            organisationNameError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          disabled={true}
                          // onChange={(e) => {
                          //   handleNameChange(e);
                          // }}
                        />
                      </div>
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>Workspace Members*</h3>
                        <input
                          type="text"
                          value={membersCount}
                          placeholder="Enter your employees count"
                          className={`px-[11px] py-[12px] max-w-full border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            membersCountError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          onChange={(e) => {
                            handleMemberCountChange(e);
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>Country or region*</h3>
                        <div className="border-[#D9D9D9] border-[1px] rounded-[8px]">
                          <CountryDropdown
                            value={country}
                            onChange={(val) => setCountry(val)}
                            className="country focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>Address*</h3>
                        <input
                          type="text"
                          value={streetName}
                          placeholder="Street name"
                          className={`px-[11px] py-[12px] max-w-full border-[#D9D9D9] border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            streetNameError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          onChange={(e) => {
                            handleStreetName(e);
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-2 w-full gap-[13px]">
                        <input
                          type="text"
                          value={cityName}
                          placeholder="City name"
                          className={`px-[11px] py-[12px] max-w-full border-[#D9D9D9] border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            cityNameError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          onChange={(e) => {
                            handleCityName(e);
                          }}
                        />
                        <input
                          type="text"
                          value={stateName}
                          placeholder="State name"
                          className={`px-[11px] py-[12px] max-w-full border-[#D9D9D9] border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            stateNameError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          onChange={(e) => {
                            handleStateName(e);
                          }}
                        />
                        <input
                          type="text"
                          value={postalCode}
                          placeholder="Postal code"
                          className={`px-[11px] py-[12px] max-w-full border-[#D9D9D9] border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none ${
                            postalCodeError
                              ? "border-[#F92A4B]"
                              : "border-[#D9D9D9]"
                          }`}
                          onChange={(e) => {
                            handlePostalCodeChange(e);
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-[8px] w-full">
                        <h3>
                          GSTIN ID{" "}
                          <span className="text-[#5C5C5C]">(optional)</span>
                        </h3>
                        <input
                          type="text"
                          value={GSTIN}
                          placeholder="12ABCDE3456F7Z8"
                          className="px-[11px] py-[12px] max-w-full border-[#D9D9D9] border-[1px] rounded-[8px] bg-transparent text-[15px] text-[#5C5C5C] placeholder:text-[#5C5C5C] placeholder:text-[15px] focus:outline-none"
                          onChange={(e) => {
                            handleGSTINChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="w-full py-[11px] bg-[#007DE5] rounded-[6px] text-[#FFFFFF] text-[14px] nunito-semibold"
                    onClick={() => startPayment && handleSubmit()}
                  >
                    Purchase $1
                  </button>
                </div>
              </div>
              <div
                className="rounded-[8px] w-full"
                style={{ boxShadow: "0px 2px 6px 0px #00000040" }}
              >
                <div className="px-[22px] py-[11px] bg-[#FFC700] rounded-t-[8px] flex justify-between">
                  <div>
                    <h3 className="nunito-semibold text-[#000000] text-[20px]">
                      workfast.ai estimate
                    </h3>
                    <h4 className="nunito-normal text-[#000000] text-[14px]">
                      Billed monthly
                    </h4>
                  </div>
                  <select
                    id="countries"
                    class="bg-[#FFC700] border border-[#3D3C41] text-[#000000] max-h-[32px] my-auto nunito-semibold text-[14px] rounded-lg  block px-[12px] py-[5px] focus:outline-none"
                    value={selectedCurrency}
                    onChange={handleChange}
                  >
                    {specialData?.allowedCurrency?.map((each) => (
                      <option value={each?.currency}>{each?.currency}</option>
                    ))}
                  </select>
                </div>
                <div className="rounded-b-[8px] py-[32px]">
                  <div className="px-[24px] border-b-[1px] border-[#D9D9D9] pb-[32px] flex flex-col gap-[22px]">
                    <h3 className="nunito-semibold text-[#000000] text-[16px]">
                      Top features:
                    </h3>
                    <ul className="flex flex-col gap-[12px]">
                      {index1
                        .slice(0, showAll ? index1.length : 4)
                        .map((item, index) => (
                          <div className="flex gap-[2px]" key={index}>
                            <img
                              src="https://dz1x1c630cl14.cloudfront.net/webassets/check.svg"
                              alt="check"
                            />
                            <li className="text-[#000000] nunito-normal text-[14px]">
                              {item.title}
                            </li>
                          </div>
                        ))}
                    </ul>
                    {!showAll && (
                      <h3
                        onClick={handleSeeMore}
                        className="text-[#000000] text-[16px] nunito-medium cursor-pointer underline"
                      >
                        See all features
                      </h3>
                    )}
                  </div>
                  <div className="pt-[32px] px-[24px] flex flex-col gap-[32px]">
                    <div className="flex flex-col gap-[14px] text-[#000000] nunito-normal text-[16px]">
                      <div className="flex justify-between items-center">
                        <h3>
                          {
                            specialData?.allowedCurrency?.filter(
                              (e) => e?.currency === selectedCurrency
                            )[0]?.symbol
                          }
                          {
                            specialData?.plan?.filter(
                              (e) => e?.currency === selectedCurrency
                            )[0]?.monthly
                          }{" "}
                          × {membersCount === "" ? 1 : membersCount} members x 1
                          month
                        </h3>
                        <h3>
                          {selectedCurrency === "USD"
                            ? "$"
                            : selectedCurrency === "INR"
                            ? "₹"
                            : selectedCurrency === "EURO"
                            ? "€"
                            : "¥"}
                          {totalAmount}
                        </h3>
                      </div>
                      <h3 className="text-[14px]">
                        You're upgrading for every active member of your team.
                        If new people join or inactive members become active,
                        they'll be added to your next bill.
                      </h3>
                    </div>
                    <div className="w-full py-[22px] border-y-[1px] border-dashed border-[#D9D9D9] flex justify-between text-[#000000] nunito-normal text-[16px]">
                      <h3>Sales tax</h3>
                      <h3>
                        {selectedCurrency === "USD"
                          ? "$"
                          : selectedCurrency === "INR"
                          ? "₹"
                          : selectedCurrency === "EURO"
                          ? "€"
                          : "¥"}
                        0
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-screen flex justify-center items-center mx-auto bg-[#FAFAFB]">
              <div className="w-[200px]">
                <LazyLottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: cardLoading,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-screen flex justify-center items-center mx-auto bg-[#FAFAFB]">
          <div className="w-[200px]">
            <LazyLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadMore,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
