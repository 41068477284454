import React from 'react'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { FaRegSquare, FaSquareCheck } from "react-icons/fa6";
import axios from 'axios';
import API from '../../../utils/API';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftCircleFill } from "react-icons/bs";

export default function ResellerForm() {
    const navigate = useNavigate();
    const [name, setName] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [companyEmail, setCompanyEmail] = React.useState('');
    const [companyWebsite, setCompanyWebsite] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [industries, setIndustries] = React.useState('');
    const [previousState, setPreviousState] = React.useState('');
    const [previousStateReason, setPreviousStateReason] = React.useState('');
    const [marketTarget, setMarketTarget] = React.useState('');

    const [companyEmailError, setCompanyEmailError] = React.useState(false);
    const [mobileNumberError, setMobileNumberError] = React.useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(API.RESELLER,
                {
                    type: "Reseller",
                    personal_name: name,
                    company_name: companyName,
                    company_email: companyEmail,
                    company_website: companyWebsite,
                    countryCode: selectedCountry,
                    mobileNumber: mobileNumber.replace(`+${selectedCountry}`, ''),
                    location: address,
                    company_primary_activities: industries,
                    existing_partenship: previousState === "YES" ? previousState + `,${previousStateReason}` : previousState,
                    size_of_market: marketTarget
                }
            );
            if (data?.statusCode === 200) {
                setName('');
                setCompanyName('');
                setCompanyEmail('');
                setCompanyWebsite('');
                setMobileNumber('');
                setSelectedCountry('');
                setAddress('');
                setIndustries('');
                setPreviousState('');
                setPreviousStateReason('');
                setMarketTarget('');
                setCompanyEmailError(false);
                setMobileNumberError(false)
                navigate('/partnership-program')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleName = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setName(e.target.value)
        }
    }

    const handleCompanyName = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setCompanyName(e.target.value)
        }
    }

    const handleEmail = (e) => {
        setCompanyEmail(e.target.value)
        const email = e.target.value;
        const gmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === "") {
            setCompanyEmailError(false);
        }
        else if (!gmailRegex.test(email)) {
            setCompanyEmailError(true);
        } else {
            setCompanyEmailError(false);
        }
    }

    const handleCompanyWebsite = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setCompanyWebsite(e.target.value)
        }
    }

    const handleAddress = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setAddress(e.target.value)
        }
    }

    const handleIndustries = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setIndustries(e.target.value)
        }
    }

    const handlePreviousStateReason = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setPreviousStateReason(e.target.value)
        }
    }

    const handleMarketTarget = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            const inputValue = e.target.value;
            if (inputValue.startsWith(' ')) e.target.value = inputValue.trimStart();
            setMarketTarget(e.target.value)
        }
    }



    return (
        <div className="bg-white w-full px-[20px] sm:px-0 py-[90px] lg:py-[134px] h-full relative">
            <div className='h-[133px] lg:h-[177px] xl:h-[247px] w-full absolute top-0 hidden sm:flex' style={{ boxShadow: "0px 1px 0px 0px #00000040" }}>
                <BsArrowLeftCircleFill className='text-[30px] mt-[30px] ml-[30px] relative z-10 cursor-pointer' onClick={() => {navigate('/partnership-program')}}/>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/form_bg.svg" alt='Img' className='absolute right-0 top-0' />
            </div>
            <div className='w-full sm:w-9/12 xl:w-8/12 mx-auto border-[#C1C1C1] border rounded-[22px] bg-white relative z-10'>
                <div className='p-[20px] lg:p-[40px] sm:flex gap-[26px] lg:gap-[42px] items-center border-b border-[#C1C1C1]'>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/campaignIcon.svg" alt='campaignIcon' className='w-[120px] sm:w-auto mx-auto' />
                    <div>
                        <h2 className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[42px] font-bold text-center sm:text-start'>Workfast.ai </h2>
                        <h4 className='mt-[16px] xl:mt-[26px] text-[#3D3D3D] text-[16px] sm:text-[20px] lg:text-[32px] font-medium text-center sm:text-start'>Join Our Reseller Partnership Program</h4>
                    </div>
                </div>
                <div className='p-[20px] lg:p-[40px] flex flex-col gap-[18px] sm:gap-[26px] lg:gap-[42px]'>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Personal Name :</h3>
                        <input type='text' value={name} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleName(e); }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Company Name :</h3>
                        <input type='text' value={companyName} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleCompanyName(e); }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Company Email :</h3>
                        <input type='text' value={companyEmail} className={`max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px] ${companyEmailError ? "border-[#FF3434]" : "border-[#C1C1C1]"}`} onChange={(e) => { handleEmail(e); }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Company website :</h3>
                        <input type='text' value={companyWebsite} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleCompanyWebsite(e); }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Contact number with country code:</h3>
                        <PhoneInput defaultCountry="us" value={mobileNumber} onChange={(phone, country) => { setMobileNumber(phone); setSelectedCountry(country?.country?.dialCode); setMobileNumberError(false); }} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px] reseller' />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Company Address and location:</h3>
                        <input type='text' value={address} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleAddress(e) }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Describe your company’s primary business activities and services:</h3>
                        <input type='text' value={industries} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleIndustries(e) }} />
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>Do you have any existing Reselling partnerships with similar technology or software companies? If so, please list them:</h3>
                        <div className='flex items-center gap-[10px] mt-[4px] sm:mt-[12px] lg:mt-[22px]' onClick={() => { setPreviousState('YES') }}>
                            {previousState !== "YES" ? <FaRegSquare className='text-[16px] lg:text-[20px] text-[#384258]' /> : <FaSquareCheck className='fill-[#27AE60] text-[16px] lg:text-[20px]' />}
                            <div className='text-[#3D3D3D] text-[18px] sm:text-[20px] lg:text-[24px] font-normal'>Yes</div>
                        </div>
                        <div className='flex items-center gap-[10px] mt-[4px] sm:mt-[12px] lg:mt-[22px] cursor-pointer' onClick={() => { setPreviousState('NO') }}>
                            {previousState !== "NO" ? <FaRegSquare className='text-[16px] lg:text-[20px] text-[#384258]' /> : <FaSquareCheck className='fill-[#27AE60] text-[16px] lg:text-[20px]' />}
                            <div className='text-[#3D3D3D] text-[18px] sm:text-[20px] lg:text-[24px] font-normal'>No</div>
                        </div>
                        {previousState === "YES" && (
                            <input type='text' value={previousStateReason} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handlePreviousStateReason(e) }} />
                        )}
                    </div>
                    <div>
                        <h3 className='text-[#3D3D3D] font-medium text-[18px] sm:text-[20px] lg:text-[24px]'>What is the size of your target market or customer base?</h3>
                        <input type='text' value={marketTarget} className='max-w-full w-full bg-transparent text-[16px] lg:text-[20px] text-[#000] font-medium focus:outline-none h-[40px] lg:h-[50px] border-[#C1C1C1] border rounded-[8px] mt-[4px] sm:mt-[12px] lg:mt-[22px] px-[20px]' onChange={(e) => { handleMarketTarget(e) }} />
                    </div>
                    <button className={`'w-full rounded-[11px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[22px] lg:text-[28px] font-semibold text-center h-[56px] lg:h-[66px] ${name === "" || companyName === "" || companyEmail === "" || companyEmailError || mobileNumber.replace(`+${selectedCountry}`, '') === "" || mobileNumberError || address === "" || industries === ""  || previousState === "" || (previousState === "YES" && previousStateReason === "") || marketTarget === "" ? "opacity-30" : "opacity-100x"}`} disabled={name === "" || companyName === "" || companyEmail === "" || companyEmailError || mobileNumber.replace(`+${selectedCountry}`, '') === "" || mobileNumberError || address === "" || industries === "" ||  previousState === "" || (previousState === "YES" && previousStateReason === "") || marketTarget === ""} onClick={(e) => { handleSubmit(e) }}>Submit</button>
                </div>
            </div>
        </div>
    )
}
