import React from 'react'
import ChatToTask from './chattotask';
import Calendar from './calendar';
import Emails from './email';
import AiIntelligence from './ai-intelligence';
import PersonalisationAndIntegration from './personalisation-integration';
import BottomUpApproach from './bottom-up-approach';
import MobileFirst from './mobile-first';
import Top7Ways from './top-7-ways';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

export default function Content({ tocData }) {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };
    return (
        <div className='flex px-[20px] md:px-0 md:w-[88%] mx-auto gap-[40px] relative z-[1]'>
            <div className='flex flex-col gap-[20px] md:gap-[30px] lg:gap-[50px] w-full md:w-[65%] overflow-y-auto'>
                <div id='top-7-ways-workfast.ai'><Top7Ways /></div>
                <div id='mobile-first'><MobileFirst /></div>
                <div id='bottom-up-approach'><BottomUpApproach /></div>
                <div id='personalisation-integration'><PersonalisationAndIntegration /></div>
                <div id='ai-intelligence'><AiIntelligence /></div>
                <div id='email'><Emails /></div>
                <div id='calendar'><Calendar /></div>
                <div id='chat-to-task'><ChatToTask /></div>
            </div>
            <aside className='sticky right-0 top-[40px] self-start w-[35%] rounded-[8px] hidden md:block'>
                <h3 className='text-[#FFFFFF] text-[18px] lg:text-[22px] font-medium pl-[9px]'>Table of contents</h3>
                <ul className={`mt-[10px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section, index) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] p-[9px] cursor-pointer rounded-[6px] items-start ${activeSection === section.id ? "bg-[#17214699]" : "bg-transparent"}`}
                                onSetActive={handleSetActive}
                            >
                                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/arrow_left.svg' alt='arrow' className={`w-[20px] lg:w-auto mt-[2px] lg:mt-[4px]`} />
                                <h3 className='text-[#FFFFFF] text-[16px] lg:text-[20px] font-medium'>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className='mt-[40px] border-t border-[#A8A8A8] pt-[31px] flex flex-col gap-[12px]'>
                    <h3 className='text-[#FFFFFF] font-extrabold text-[17px]'>Create your own workspace in  Workfast today</h3>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#E6E6E6]'>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg" alt='' />
                            <h4>Communicate with your team</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#E6E6E6]'>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg" alt='' />
                            <h4>Add and complete Tasks</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#E6E6E6]'>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg" alt='' />
                            <h4>Engage with your team</h4>
                        </div>
                    </div>
                    <button className='bg-gradient-to-l from-[#FDD01F] via-[#FDD320] to-[#F8861B] w-9/12 py-[13px] rounded-[14px] text-[#221B00] text-[16px] font-semibold' onClick={() => {navigate('/signup')}}>Get Started</button>
                </div>
            </aside>
        </div>
    )
}
