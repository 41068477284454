import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import API from '../../../../utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import intro from "../../../../images/intro.webp"


export default function Intro({ setIsForm, email, setEmail }) {
    const navigate = useNavigate();
    // const [email, setEmail] = React.useState('');

    // const handleEmail = (e) => {
    //     const email = e.target.value;
    //     setEmail(email);
    // };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const response = await axios.post(API.BUTTON,
    //         {
    //             "eventName": "REQUEST_DEMO_MAIN"
    //         }
    //     );
    //     if (response.data.statusCode === 200) {
    //         setIsForm(true)
    //     }
    // };

    const handleClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "SIGN_UP_FOR_FREE_MAIN"
            }
        );
        if (response.data.statusCode === 200) {
            // window.location.href = 'https://app.workfast.ai/';
            navigate('/form')
        }
    };

    React.useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="bg-cover bg-center h-full w-full flex flex-col items-center relative mb-[20px] lg:mb-[60px] xl:mb-[100px] overflow-hidden">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
            <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <div className='shooting-stars1'></div>
            <div className='shooting-stars'></div>
            <div className='text-center relative z-[10]'>
                <h1 className='text-[24px] sm:text-[40px] xl:text-[70px] text-[#FFFFFF] font-bold mt-[40px]'>One intelligent Platform</h1>
                <h2 className='text-[24px]  sm:text-[40px] xl:text-[70px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent font-bold'>for your team to work faster</h2>
            </div>
            <div className='flex gap-[22px] items-center mt-[40px]'>
                <button className='py-[8px] px-[12px] w-[220px] lg:w-[253px] h-[44px] lg:h-[54px] rounded-[8px] bg-gradient-to-bl from-[#FDD01F] via-[#F8C240] to-[#F8861B] border-[1px] border-[#995900] b1 relative z-[10]' onClick={(e) => { handleClick(e) }}>
                    <span className='text text-[#2A2200] text-[16px] lg:text-[18px] font-semibold flex gap-[10px] items-center justify-center'>Book a Demo <FaArrowRightLong /></span>
                    <span class="shimmer"></span>
                </button>
            </div>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/intro.webp" alt='intro' className='mt-[15px] lg:mt-[25px] xl:mt-[40px] relative z-[10]' width={1066} height={700}/>
                {/* <img src={intro} alt='intro' className='mt-[15px] lg:mt-[25px] xl:mt-[40px] relative z-[10] px-[20px] sm:px-0' width={1066} height={700} /> */}
        </div>
    )
}
