import React from 'react'

export default function SoftwareToolComparatively() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>How Workfast.ai is most efficient software tool comparatively ?</h2>
            <div className='flex flex-col gap-[30px]'>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>1. AI :</h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Workfast.ai is most efficient for this one reason . Artificial Intelligence which is built over your companies data will provide basic to difficult suggestions whenever you need it . For example if you ask for projects which is completed above 80%, it will give the results . if you ask for a particular persons performance in a projects it will give it no time. similarly , leaves, Approvals, project decisions are very easy to manage.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>2. Automation : </h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Repetitive tasks and process are reminded and set precisely according to the users wish, thus reducing the time and increasing productivity. </p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>3. Integration : </h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>While working in Workfast.ai it is very simple to integrate any software tools which is much needed to your present work . For example , you can integrate , Notion, slack , Asana, jira and the list extends. It makes work easier and does not interrupt the work flow.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>4. Channel and Chat :</h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>This features will help users to communicate professionally on certain projects or tasks . The user can create numerous channels for communication , share images , videos , links , documents and send voice note. </p>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>while chatting , the user can change the message into tasks and assign it to any members without going out the channel or chat. This helps in evading miscommunication and increase the efficiency of assigning tasks.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>5. Task and project management : </h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Every projects are divided into multiple tasks . one can create projects and assign as many team members they want. once done , the user can see the improvements in the dashboard . Similarly , the projects can be given status, tags and priority for easy understanding. Once the tasks are getting complete , the dashboard shows weekly analytics , project owner details and project description etc. </p>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>one can create their own workflow for the project . when needed the team members can message and comment on the project itself.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h3 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold relative z-[1]'>6. Mobile and web view : </h3>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Giving same experience as web view to mobile view is a difficult task. Workfast.ai accommodates the same experience as web thus giving relief to people who don't like to carry laptops to travelling places. This helps people who are managers , VP’s, CEO’s , CXO’s and people who are freelancers and WFH employees.</p>
                </div>
            </div>
        </div>
    )
}
