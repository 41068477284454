import React from 'react'

export default function Privacy() {
  return (
    <div className='w-full h-screen relative p-10'>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/Glowing.svg" alt='glow' className='fixed w-full h-full top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-0' />
      <div>
        <h1 className='DarkerGrostesqueSemibold text-[30px] sm:text-[40px] lg:text-[80px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent text-center z-[1]'>Privacy Policy</h1>
        <h3 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[30px]'>Last updated on 01-03-2024 15:33:44</h3>
        <p className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-normal mt-[30px] z-10 relative'>This privacy policy <span className='text-[#FDD320]'>(“Policy”)</span> relates to the manner <span className='bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>workfast.ai</span>  <span className='text-[#FDD320]'>(“we”, “us”, “our”)</span> in which we use, handle and process the data that you provide us in connection with using the products or services we offer. By using this website or by availing goods or services offered by us, you agree to the terms and conditions of this Policy, and consent to our use, storage, disclosure, and transfer of your information or data in the manner described in this Policy.</p>
        <p className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'>We are committed to ensuring that your privacy is protected in accordance with applicable laws and regulations. We urge you to acquaint yourself with this Policy to familiarize yourself with the manner in which your data is being handled by us.</p>
        <h4 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-normal mt-[30px] z-10 relative'><span className='bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent'>workfast.ai</span> may change this Policy periodically and we urge you to check this page for the latest version of the Policy in order to keep yourself updated.</h4>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>What data is being collected</h2>
         <h3 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-medium mt-[30px] z-10 relative'>We may collect the following information from you:</h3>
         <div className='ml-[50px]'>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Name</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Contact information including address and email address</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Demographic information or, preferences or interests</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Personal Data or Other information relevant/ required for providing the goods or services to you</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> The meaning of Personal Data will be as defined under relevant Indian laws</h4>
         </div>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[30px] z-10 relative'><span className='text-[#FDD320]'>Note: </span>Notwithstanding anything under this Policy as required under applicable Indian laws, we will not be storing any credit card, debit card or any other similar card data of yours. Please also note that all data or information collected from you will be strictly in accordance with applicable laws and guidelines.</p>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>What we do with the data we gather</h2>
         <h3 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-medium mt-[30px] z-10 relative'>We require this data to provide you with the goods or services offered by us including but not limited, for the below set out purposes:</h3>
         <div className='ml-[50px]'>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Internal record keeping.</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> For improving our products or services.</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> For providing updates to you regarding our products or services including any special offers.</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> To communicate information to you</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> For internal training and quality assurance purposes</h4>
         </div>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>Who do we share your data with</h2>
         <h3 className='text-[14px] sm:text-[20px] lg:text-[28px] text-[#FFF] font-medium mt-[30px] z-10 relative'>We may share your information or data with:</h3>
         <div className='ml-[50px]'>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Third parties including our service providers in order to facilitate the provisions of goods or services to you, carry out your requests, respond to your queries, fulfil your orders or for other operational and business reasons</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> With our group companies (to the extent relevant)</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Our auditors or advisors to the extent required by them for performing their services</h4>
          <h4 className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'><span className='text-[#FDD320]'>•</span> Governmental bodies, regulatory authorities, law enforcement authorities pursuant to our legal obligations or compliance requirements</h4>
         </div>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>How we use cookies</h2>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'>We use "cookies" to collect information and to better understand customer behaviour. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to avail our goods or services to the full extent. We do not control the use of cookies by third parties. The third party service providers have their own privacy policies addressing how they use such information.</p>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>Your rights relating to your data</h2>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-nnormal mt-[20px] z-10 relative'><span className='text-[#FDD320] font-medium'>Right to Review </span>- You can review the data provided by you and can request us to correct or amend such data (to the extent feasible, as determined by us). That said, we will not be responsible for the authenticity of the data or information provided by you.</p>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-nnormal mt-[20px] z-10 relative'><span className='text-[#FDD320] font-medium'>Withdrawal of your Consent </span>- You can choose not to provide your data, at any time while availing our goods or services or otherwise withdraw your consent provided to us earlier, in writing to our email ID: payments@workfast.ai In the event you choose to not provide or later withdraw your consent, we may not be able to provide you our services or goods.Please note that these rights are subject to our compliance with applicable laws.</p>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>How long will we retain your information or data?</h2>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'>We may retain your information or data (i) for as long as we are providing goods and services to you; and (ii) as permitted under applicable law, we may also retain your data or information even after you terminate the business relationship with us. However, we will process such information or data in accordance with applicable laws and this Policy.</p>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>Data Security</h2>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'>We will use commercially reasonable and legally required precautions to preserve the integrity and security of your information and data.</p>
      </div>
      <div>
         <h2 className='text-[18px] sm:text-[24px] lg:text-[32px] text-[#FFF] font-bold mt-[60px]'>Queries/ Grievance Officer</h2>
         <p className='text-[12px] sm:text-[18px] lg:text-[26px] text-[#FFF] font-medium mt-[20px] z-10 relative'>For any queries, questions or grievances about this Policy, please contact us using the contact information provided on this website.</p>
      </div>
    </div>
  )
}
