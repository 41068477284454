import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./offer.css";
import LazyLottie from "react-lottie";

import API from "../../utils/API";
import helperUtils from "../../utils/helperUtils";
import paymentStore from "../../utils/store/paymentStore";

import cardLoading from "../../images/cardLoading.json";
import loadMore from "../../images/loadingPayment.json";

import cave from "../../images/cave.svg";
import teamSize from "../../images/teamSize.png";
import gift from "../../images/gift.png";
import dots from "../../images/dots.svg";
import { GoArrowUpRight } from "react-icons/go";
import channel from "../../images/Channel-Icon.svg";
import task from "../../images/Task-Icon.svg";
import chat from "../../images/Chat-Icon.svg";
import alerrt from "../../images/Alert-Icon.svg";
import people from "../../images/People-Icon.svg";
import Reports from "../../images/ReportsAndAnalytics-Icon.svg";

import { IoIosArrowRoundForward } from "react-icons/io";

const Offer = () => {
  const razorpayKey = process.env.REACT_APP_RAZOR_PAY_KEY;
  const navigate = useNavigate();

  const [afterLoading, setAfterLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [expiryDate, setExpiryDate] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (expiryDate) {
      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(timer); // Clean up the interval on component unmount
    }
  }, [expiryDate]);

  const calculateTimeLeft = () => {
    const targetDate = new Date(expiryDate).getTime(); // Expiry time
    const now = new Date().getTime(); // Current time
    const difference = targetDate - now; // Subtract current time from expiry time

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert from ms to days
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ); // Hours left
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); // Minutes left
      const seconds = Math.floor((difference % (1000 * 60)) / 1000); // Seconds left

      setTimeLeft({ days, hours, minutes, seconds });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const [sliderValue, setSliderValue] = useState(200); // Default value 50
  const maxValue = 2000;

  const [value, setValue] = useState(200);

  const handleChange = (e) => {
    setValue(Number(e.target.value)); // Ensure the value is updated correctly

    setSliderValue(e.target.value);
  };

  const startPayment = async () => {
    try {
      setPaymentLoading(true);
      let sendData = {};

      //   if (organisationName !== "")
      //     sendData["organizationName"] = organisationName;
      if (value !== null) sendData["membersCount"] = value;
      //   if (country !== "") sendData["country"] = country;
      //   if (streetName !== "") sendData["address"] = streetName;
      //   if (cityName !== "") sendData["city"] = cityName;
      //   if (stateName !== "") sendData["state"] = stateName;
      //   if (postalCode !== "") sendData["postalCode"] = postalCode;
      //   if (GSTIN !== "") sendData["gstinId"] = GSTIN;

      const { data } = await axios.post(
        API.UPDATE_ORGANIZATION_INFO,
        sendData,
        {}
      );

      if (data.data.infoId) {
        displayRazorpay(data.data.infoId);
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const getOfferDetails = async (infoId) => {
    try {
      const result = await axios.post(
        API.GET_OFFER_DETAILS,
        {
          currency: "USD",
        },
        {}
      );
      setExpiryDate(result?.data?.offerDuration);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getOfferDetails();
  }, []);

  const getPayOrder = async (infoId) => {
    if (infoId !== null) {
      try {
        const result = await axios.post(
          API.SPECIAL_INITIATE_PAYMENT,
          {
            paymentGateway: "RAZORPAY",
            estimateDurationType: "MONTH",
            currency: "USD",
            infoId: infoId,
          },
          {}
        );

        return result;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const paymentSuccess = async (data) => {
    try {
      const result = await axios.post(API.PAYMENT_SUCCESS, data, {});
      paymentStore.setState({ userEmail: result.data.response.email });

      let timer;
      if (result.data.response.email) {
        timer = setTimeout(() => {
          //   console.log(result);
          console.log("paymentStore userEmail", result.data.response.email);
          setAfterLoading(false);

          navigate("/payment-signup");
        }, 5000);
      }
      return () => {
        if (timer) clearTimeout(timer);
      };
    } catch (error) {
      console.log(error);
      setAfterLoading(false);
      navigate("/payment-signup");
    }
  };

  const displayRazorpay = async (infoId) => {
    const res = await helperUtils.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let orderData = await getPayOrder(infoId);

    if (!orderData) {
      alert("Server error. Are you online?");
      return;
    }

    const amount = orderData?.data?.amount;
    const currency = orderData?.data?.currency;
    const options = {
      key: razorpayKey,
      amount: amount,
      currency: currency,
      name: "Workfast.ai Offer",
      description: "special_offer_" + infoId,
      // image: { logo },
      order_id: orderData?.data?.id,
      handler: async function (response) {
        const paymentData = {
          infoId: infoId,
          orderCreationId: orderData?.data?.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          paymentGateway: "RAZORPAY",
        };

        paymentStore.setState({ infoId: infoId });
        paymentStore.setState({ orderCreationId: orderData?.data?.id });
        setAfterLoading(true);
        await paymentSuccess(paymentData);
      },
      // prefill: {
      //   name: "Soumya Dey",
      //   email: "SoumyaDey@example.com",
      //   contact: "9999999999",
      // },
      notes: {
        infoId: infoId,
        // userEmail: workspaceDetails.userEmail,
        // userName: workspaceDetails.userName,
        // workspaceName: workspaceDetails?.workspaceName,
      },
      theme: {
        color: "#61dafb",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "Pay via Card",
              instruments: [
                {
                  method: "card",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },
      modal: {
        ondismiss: function () {
          console.log("Razorpay payment popup closed by user.");
        },
      },
    };
    setPaymentLoading(false);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="flex flex-col justify-start items-center w-full h-full min-h-[100vh] bg-[#FFFFFF] relative overflow-hidden gap-[30px] md:gap-[60px] lg:gap-[100px]">
      {paymentLoading ? (
        <div className="w-full h-screen flex justify-center items-center mx-auto bg-[#FAFAFB]">
          <div className="w-[200px]">
            <LazyLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: cardLoading,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </div>
      ) : !afterLoading ? (
        <>
          <div className="flex justify-between items-center w-full px-4 py-4">
            <div
              className="w-fit px-[12px] py-2 border border-[#CECEEA] rounded-xl"
              style={{ boxShadow: "0px 4px 7px 0px #432E860A" }}
              onClick={() => navigate("/launch-offer")}
            >
              <img
                src="https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg"
                alt="logo"
                className="w-[100px] sm:w-auto"
              />
            </div>
            {/* <button
              className="px-[14px] py-2 border border-[#CECEEA] text-sm rounded-xl"
              style={{ boxShadow: "0px 4px 7px 0px #432E860A" }}
            >
              Contact Sales
            </button> */}
          </div>
          <div className="flex flex-col justify-center items-center gap-[32px] px-[20px] sm:px-0 w-full sm:w-10/12 mx-auto">
            <div>
              <h3 className="font-bold text-[#0A0800] text-[24px] sm:text-[40px] xl:text-[55px] text-center">
                Time’s Ticking! Exclusive & Limited Deal in
              </h3>
              <h3 className="font-bold text-[#0A0800] text-[24px] sm:text-[40px] xl:text-[55px] text-center">
                Workfast.ai – Purchase Now for{" "}
                <span className="text-[#FF2D55] line-through decoration-[#FF2D55] text-[14px] sm:text-[30px] xl:text-[42px] font-semibold">
                  $7
                </span>{" "}
                <span className="text-transparent bg-[#34C759] bg-clip-text relative">
                  $1{" "}
                  <img
                    src={cave}
                    alt=""
                    className="absolute right-[10px] bottom-0 sm:bottom-[5px] w-[30px] sm:w-[70px]"
                  />
                </span>{" "}
                <span className="text-[#676767] font-semibold text-[10px] sm:text-[12px] xl:text-[16px]">
                  / User / Month
                </span>
              </h3>
            </div>
            <h4 className="font-normal text-[#8E8E93] text-[14px] sm:text-[24px] sm:w-8/12 xl:w-1/2 text-center">
              A powerful tool for Communication and Task management currently
              your team needs
            </h4>
            <div className="flex gap-[20px] sm:gap-[32px] items-end">
              <div className="flex flex-col gap-[10px] items-center justify-center">
                <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                  Days
                </div>
                <div className="text-[#FF3B30] text-[28px] sm:text-[72px] font-bold">
                  {String(timeLeft.days).padStart(2, "0")}
                </div>
              </div>
              <div className="h-[30px] sm:h-[50px] bg-[#8D8D8D] w-[2px] mb-[10px] sm:mb-[30px]"></div>
              <div className="flex flex-col gap-[10px] items-center justify-center">
                <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                  Hours
                </div>
                <div className="text-[#FF9500] text-[28px] sm:text-[72px] font-bold">
                  {String(timeLeft.hours).padStart(2, "0")}
                </div>
              </div>
              <img
                src={dots}
                alt=""
                className="mb-[15px] sm:mb-[35px] w-[4px] sm:w-auto"
              />
              <div className="flex flex-col gap-[10px] items-center justify-center">
                <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                  Minutes
                </div>
                <div className="text-[#FFCC00] text-[28px] sm:text-[72px] font-bold">
                  {String(timeLeft.minutes).padStart(2, "0")}
                </div>
              </div>
              <img
                src={dots}
                alt=""
                className="mb-[15px] sm:mb-[35px] w-[4px] sm:w-auto"
              />
              <div className="flex flex-col gap-[10px] items-center justify-center">
                <div className="text-[#555555] text-[12px] sm:text-[14px] font-semibold">
                  Seconds
                </div>
                <div className="text-[#34C759] text-[28px] sm:text-[72px] font-bold">
                  {String(timeLeft.seconds).padStart(2, "0")}
                </div>
              </div>
            </div>
            <div className="text-center">
              <h2 className="font-bold text-[24px] sm:text-[40px]">
                What is your team size?
              </h2>
              <p className="my-2">
                Don’t worry, you can add more members anytime after setup.
              </p>
              <img src={teamSize} alt="" className="w-[50%] mx-auto my-8" />
            </div>
            <div className="flex flex-col items-center w-[100vw]">
              <h2 className="text-xl text-center font-semibold">
                How many people work at your company?
              </h2>

              {/* Slider Container */}
              <div className="relative w-[80vw] sm:w-full max-w-lg mt-10">
                <div className="relative w-full h-6 rounded-full appearance-none border-2 border-[#22c55d] p-0.5">
                  <input
                    type="range"
                    min="1"
                    max="2000"
                    step="1"
                    value={value}
                    onChange={handleChange} // Correctly handling the change
                    className="w-full h-3 rounded-full appearance-none absolute bottom-1"
                    style={{
                      background: `linear-gradient(to right, #22c55d ${
                        (sliderValue / maxValue) * 100
                      }%, transparent ${(sliderValue / maxValue) * 100}%)`,
                    }}
                  />
                </div>

                {/* Slider Value Tooltip */}
                <div
                  className="absolute top-[-2rem] transform -translate-x-1/2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-lg"
                  style={{ left: `${((value - 1) / 1999) * 100}%` }}
                >
                  {value}
                </div>

                {/* Markers */}
                {/* <div className="absolute top-2 left-0 w-full flex justify-between">
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                </div> */}
              </div>

              {/* Label Markers */}
              <div className="flex justify-between w-[80vw] sm:w-full max-w-lg text-xs ml-1 mt-2 text-gray-600">
                <span>1</span>
                <span className="ml-6">500</span>
                <span className="ml-4">1000</span>
                <span className="ml-2">1500</span>
                <span className="">2000+</span>
              </div>

              {/* Display Selected Value */}
              <div className="flex flex-col items-center mt-6">
                <style>
                  {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
                </style>
                <input
                  type="number"
                  className="text-2xl sm:text-4xl font-bold text-green-600 border border-green-300 text-center w-[150px] px-6 py-2 rounded-md"
                  value={value}
                  style={{
                    appearance: "textfield", // For most browsers
                    MozAppearance: "textfield", // For Firefox
                    WebkitAppearance: "none", // For Chrome/Safari
                    margin: 0,
                  }}
                  onChange={(e) => {
                    let inputValue;
                    inputValue = e.target.value.replace(/^0+/, "");
                    if (inputValue <= 0) {
                      setValue(0);
                      setSliderValue(0);
                    } else if (inputValue > 2000) {
                      //   setValue(2000);
                    } else {
                      setValue(inputValue);
                      setSliderValue(inputValue);
                    }
                  }}
                />
                <p className="sm:text-lg font-semibold mt-2">
                  Total no. of License
                </p>
              </div>
            </div>
            <div
              className={`bg-green-600 font-semibold w-[200px] sm:w-[400px] mx-auto mt-4 py-2 text-white text-center rounded-lg ${
                value > 0
                  ? "opacity-100 cursor-pointer"
                  : "opacity-70 cursor-not-allowed"
              }`}
              onClick={() => {
                value > 0 && startPayment();
              }}
            >
              Purchase now
            </div>
          </div>
          <div className="flex flex-col gap-[20px] sm:gap-[32px] justify-center items-center w-10/12 mx-auto">
            <h3 className="text-[#2A2924] text-[22px] sm:text-[34px] xl:text-[46px] font-semibold text-center w-10/12">
              If WorkFast.ai offers more of the features you need than any
              tools, why settle for less?
            </h3>
            <div className="w-[215px] h-[40px] bg-[#7073931A] rounded-[40px] border-[#70739338] border flex justify-center items-center gap-[6px] text-[#2A2924] text-[14px] font-semibold">
              <div>Explore Workfast.ai</div>
              <GoArrowUpRight />
            </div>
            <div className="sm:mt-[10px] text-[#2A2924] text-[24px] sm:text-[46px] font-semibold">
              Features
            </div>
            <div className="flex flex-wrap justify-center items-center gap-y-[32px] gap-x-[64px] lg:w-10/12 sm:mt-[10px]">
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[12px] pl-[25px] pt-[28px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={channel} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  Channel
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Communicate with your team members and share thoughts , ideas
                  and get your work done. Create private channels to share
                  important updates .{" "}
                </h4>
              </div>
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[12px] pl-[25px] pt-[28px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={task} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  Tasks
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Assign tasks and get the work done in priority basis . Let the
                  collaborators join hands for completing difficult task and
                  work as a team .
                </h4>
              </div>
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[12px] pl-[25px] pt-[28px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={chat} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  Chat
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Chat with your teammates unofficially for your colleague
                  birthday in a group or personally message to your team buddy
                  to get things organised.
                </h4>
              </div>
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[12px] pl-[25px] pt-[28px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={alerrt} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  Alert & Notification
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Get real time updates of collaboration and communication
                  activities , so that no one in the workspace miss the threads
                  or action items updates.
                </h4>
              </div>
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[8px] pl-[25px] pt-[20px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={people} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  People
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Invite guests from outside for example clients , stakeholders
                  to channel , Tasks or projects to collaborate. View members
                  log with their name , email id and designation .
                </h4>
              </div>
              <div
                className="rounded-[10px] max-w-[280px] h-[252px] flex flex-col gap-[12px] pl-[25px] pt-[28px] pb-[27px] pr-[24px]"
                style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
              >
                <img src={Reports} alt="channel" className="size-[44px]" />
                <h3 className="text-[#252432] text-[20px] font-semibold">
                  Reports and Analytics
                </h3>
                <h4 className="text-[#8987A1] text-[14px] font-normal">
                  Download task reports and analytics of teams to know more
                  about the performance . Share and present such data to clients
                  to get feedback.
                </h4>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[20px] sm:gap-[22px] justify-center items-center w-full sm:w-8/12 mx-auto px-[20px] sm:px-0">
            <h3 className="text-[#252432] text-[24px] sm:text-[60px] font-semibold">
              Pricing
            </h3>
            <h4 className="text-[#8987A1] text-[14px] sm:text-[20px] font-normal text-center w-10/12 lg:w-[64%]">
              Pricing is designed to provide exceptional value and flexibility,
              whether you're just starting out or scaling up.
            </h4>
            <div
              className="mt-[20px] rounded-[20px] px-[22px] py-[25px] flex flex-col gap-[10px] sm:gap-[22px] items-center justify-center w-full sm:w-[623px]"
              style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
            >
              <div className="text-[#252432] text-[22px] sm:text-[30px] font-bold text-center">
                Only and the best plan
              </div>
              <div className="text-[#8987A1] text-[13px] sm:text-[14px] font-normal text-center w-9/12">
                Explore what workfast.ai can offer users with unlimited benefits
              </div>
              <div className="mt-[20px] flex flex-col w-10/12 mx-auto justify-center items-center gap-[18px]">
                <div className="flex flex-col sm:flex-row  sm:justify-between items-start gap-[18px] sm:items-center w-full">
                  <div className="flex gap-[13px] items-center">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited History
                    </h6>
                  </div>
                  <div className="flex gap-[13px] items-center mr-[18px]">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited Channel
                    </h6>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row  sm:justify-between items-start gap-[18px] sm:items-center w-full">
                  <div className="flex gap-[13px] items-center">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited Tasks{" "}
                    </h6>
                  </div>
                  <div className="flex gap-[13px] items-center">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited file sharing
                    </h6>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row  sm:justify-between items-start gap-[18px] sm:items-center w-full">
                  <div className="flex gap-[13px] items-center">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited Message
                    </h6>
                  </div>
                  <div className="flex gap-[13px] items-center mr-[28px]">
                    <img
                      src="https://dz1x1c630cl14.cloudfront.net/webassets/checkimg.svg"
                      alt=""
                      className="size-[24px]"
                    />
                    <h6 className="text-[#252432] font-medium text-[14px]">
                      Unlimited Invites
                    </h6>
                  </div>
                </div>
              </div>
              <div className="mt-[20px] flex flex-col w-full justify-between items-center bg-[#FFFBEF] px-[42px] py-[27px] rounded-[10px]">
                <h4 className="text-[#34C759] text-[30px] sm:text-[60px] font-bold">
                  $1
                </h4>
                <p className="text-[#8987A1] text-[16px] sm:text-[20px] font-normal">
                  /user /month
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#FFFBEF] text-center py-8 w-[100vw]">
            <h3 className="text-xl font-semibold">
              Gift your team this focus-building software—they’ll thank you
              later.
            </h3>
            <p className="my-4 text-sm">
              Sign up today to unlock your first month for just $1.{" "}
            </p>
            <img src={gift} alt="" className="w-[50%] mx-auto my-8" />
            <div className="flex flex-col items-center">
              <h2 className="text-xl font-semibold">
                How many people work at your company?
              </h2>

              {/* Slider Container */}
              <div className="relative w-[80vw] sm:w-full max-w-lg mt-10">
                <div className="relative w-full h-6 rounded-full appearance-none border-2 border-[#22c55d] p-0.5">
                  <input
                    type="range"
                    min="1"
                    max="2000"
                    step="1"
                    value={value}
                    onChange={handleChange} // Correctly handling the change
                    className="w-full h-3 rounded-full appearance-none absolute bottom-1 left-1"
                    style={{
                      background: `linear-gradient(to right, #22c55d ${
                        (sliderValue / maxValue) * 100
                      }%, transparent ${(sliderValue / maxValue) * 100}%)`,
                    }}
                  />
                </div>

                {/* Slider Value Tooltip */}
                <div
                  className="absolute top-[-2rem] transform -translate-x-1/2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-lg"
                  style={{ left: `${((value - 1) / 1999) * 100}%` }}
                >
                  {value}
                </div>

                {/* Markers */}
                {/* <div className="absolute top-2 left-0 w-full flex justify-between">
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                  <div className="w-2 h-2 bg-white border-2 border-green-500 rounded-full"></div>
                </div> */}
              </div>

              {/* Label Markers */}
              <div className="flex justify-between w-[80vw] sm:w-full max-w-lg text-xs ml-2 mt-2 text-gray-600">
                <span>1</span>
                <span className="ml-6">500</span>
                <span className="ml-4">1000</span>
                <span className="ml-4">1500</span>
                <span className="">2000+</span>
              </div>

              {/* Display Selected Value */}
              <div className="flex flex-col items-center mt-6">
                <style>
                  {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
                </style>
                <input
                  type="number"
                  className="text-2xl sm:text-4xl font-bold text-green-600 border border-green-300 text-center w-[150px] px-6 py-2 rounded-md"
                  value={value}
                  style={{
                    appearance: "textfield", // For most browsers
                    MozAppearance: "textfield", // For Firefox
                    WebkitAppearance: "none", // For Chrome/Safari
                    margin: 0,
                  }}
                  onChange={(e) => {
                    let inputValue;
                    inputValue = e.target.value.replace(/^0+/, "");
                    if (inputValue <= 0) {
                      setValue(0);
                      setSliderValue(0);
                    } else if (inputValue > 2000) {
                      //   setValue(2000);
                    } else {
                      setValue(inputValue);
                      setSliderValue(inputValue);
                    }
                  }}
                />
                <p className="sm:text-lg font-semibold mt-2">
                  Total no. of License
                </p>
              </div>
            </div>
            <div
              className={`bg-green-600 font-semibold w-[200px] sm:w-[400px] mx-auto mt-4 py-2 text-white text-center rounded-lg ${
                value > 0
                  ? "opacity-100 cursor-pointer"
                  : "opacity-70 cursor-not-allowed"
              }`}
              onClick={() => {
                value > 0 && startPayment();
              }}
            >
              Purchase now
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-screen flex flex-col justify-center items-center mx-auto bg-[#FAFAFB]">
          <div className="w-[200px]">
            <LazyLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadMore,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              isStopped={false}
              isPaused={false}
            />
          </div>
          <h2 className="w-[50vw] text-center font-semibold">
            Payment is still processing, do not refresh or leave this page
          </h2>
        </div>
      )}
    </div>
  );
};

export default Offer;
