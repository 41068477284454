import React from 'react'
import Header from './header'
import API from '../../utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from 'react-helmet';
import { BiSolidUserAccount } from "react-icons/bi";
export default function StartupTn() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [peopleCount, setPeopleCount] = React.useState('');
    const [peopleCountError, setPeopleCountError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();


    const handleNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setName(e.target.value)
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        const email = e.target.value;
        const gmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === "") {
            setEmailError(false);
        }
        else if (!gmailRegex.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const handleCompanyNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setCompanyName(e.target.value);
        }
    }

    const handlePhoneNumberChange = (e) => {
        const phoneNumbers = e.target.value.replace(/\D/g, '');
        setPhoneNumber(phoneNumbers)
        const isValidPhoneNumber = /^\d{10}$/;
        if (phoneNumbers === "") {
            setPhoneNumberError(false);
        }
        else if (!isValidPhoneNumber.test(phoneNumbers)) {
            setPhoneNumberError(true);
        } else {
            setPhoneNumberError(false);
        }
    }

    const handlePeopleCountChange = (e) => {
        const peopleCounts = e.target.value.replace(/\D/g, '');
        setPeopleCount(peopleCounts);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { data } = await axios.post(API.DETAILS,
            {
                name: name,
                email: email,
                phone: phoneNumber,
                company_name: companyName,
                people_count: peopleCount
            });
        const response = await axios.post(API.HOST, { email: email });
        if (response.data) {
            console.log("sucess")
        }
        if (data?.statusCode === 200) {
            setName('');
            setEmail('');
            // setPhoneNumber('');
            setCompanyName('');
            setPeopleCount('');
            setLoading(false);
            navigate('/startuptn/success')
        }

    };

    return (
        <div className='flex flex-col w-full h-full bg-[#010306] relative overflow-hidden'>
            <Helmet>
                {/* <!-- Google Tag Manager --> */}
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-N743ZL57');
                    `}
                </script>
                {/* <!-- End Google Tag Manager --> */}
            </Helmet>
            <body>
                {/* <!-- Google Tag Manager (noscript) --> */}
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N743ZL57" title='googletag'
                    height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                {/* <!-- End Google Tag Manager (noscript) --> */}
            </body>
            <Header />
            <img src='https://dz1x1c630cl14.cloudfront.net/webassets/yellowglow.png' alt='yellowGlow' className='absolute top-[73px] md:top-[80px] z-[0] ' />
            <div className='relative px-[20px] md:px-0 w-full md:w-11/12 mx-auto flex flex-col justify-center items-center gap-[30px] lg:gap-[60px] overflow-hidden'>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
                <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div className='shooting-stars1'></div>
                <div className='shooting-stars'></div>
                <div className='w-[300px] md:w-[630px] bg-[#121825] rounded-[16px] p-[32px] relative z-[10] mt-[100px]'>
                    <h2 className='text-[#FFFFFF] font-semibold text-[20px] text-center'>Book a Demo with <span className='bg-clip-text text-transparent bg-gradient-to-l from-[#FDD220] to-[#FFFFFF]'>Workfast.ai?</span></h2>
                    <div className='grid md:grid-cols-2 gap-5 items-center mt-[15px] md:mt-[30px] w-full'>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/profileicon.svg' alt='user' className='w-[19px] md:w-[23x]' />
                                <h3 className='text-[#FFFFFF] text-[12px] md:text-[14px] font-medium'>Name*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] bg-[#1C2437] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                                <input type='text' className='w-full text-[#E2E8FF] placeholder:text-[#E2E8FF]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none ml-[18px]' value={name} placeholder='Enter your name' onChange={(e) => { handleNameChange(e); }} />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/emailicon.svg' alt='user' className='w-[19px] md:w-[23x]' />
                                <h3 className='text-[#FFFFFF] text-[12px] md:text-[14px] font-medium'>Email*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] bg-[#1C2437] lg:py-[10px] mt-[10px] rounded-[8px] ${emailError ? "border-[1px] border-[#F92A4B]" : ""}`}>
                                <input type='email' className='w-full text-[#E2E8FF] placeholder:text-[#E2E8FF]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none ml-[18px]' placeholder='Enter your email' value={email} onChange={(e) => { handleEmailChange(e) }} />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/phoneicon.svg' alt='user' className='w-[19px] md:w-[23x]' />
                                <h3 className='text-[#FFFFFF] text-[12px] md:text-[14px] font-medium'>Phone Number*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] bg-[#1C2437] lg:py-[10px] mt-[10px] rounded-[8px] ${phoneNumberError ? "border-[1px] border-[#F92A4B]" : ""}`}>
                                <input type='text' className='w-full text-[#E2E8FF] placeholder:text-[#E2E8FF]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none ml-[18px]' placeholder='Enter your Phone number' value={phoneNumber} onChange={(e) => { handlePhoneNumberChange(e) }} />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/companyicon.svg' alt='user' className='w-[19px] md:w-[23x]' />
                                <h3 className='text-[#FFFFFF] text-[12px] md:text-[14px] font-medium'>Company name*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] bg-[#1C2437] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                                <input type='text' className='w-full text-[#E2E8FF] placeholder:text-[#E2E8FF]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none ml-[18px]' placeholder='Enter your Company name' value={companyName} onChange={(e) => { handleCompanyNameChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className='w-full mt-[15px] md:mt-[30px]'>
                        <div className='flex items-center gap-1'>
                            {/* <img src='https://dz1x1c630cl14.cloudfront.net/webassets/companyicon.svg' alt='user' className='w-[19px] md:w-[23x]' /> */}
                            <BiSolidUserAccount className='text-[#27AE60]' />
                            <h3 className='text-[#FFFFFF] text-[12px] md:text-[14px] font-medium'>No of People in your company*</h3>
                        </div>
                        <div className={`w-full h-[38px] md:h-[44px] bg-[#1C2437] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                            <input type='text' className='w-full text-[#E2E8FF] placeholder:text-[#E2E8FF]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none ml-[18px]' placeholder='Enter a No of People in your company' value={peopleCount} onChange={(e) => { handlePeopleCountChange(e) }} />
                        </div>
                    </div>
                    {loading ?
                        <div class="loader"></div>
                        :
                        <button className={`w-full h-[38px] md:h-[44px] rounded-[8px] border-[1px] border-[#995900] bg-[#FDD01F] text-[#2A2200] text-[16px] font-medium mt-[30px] flex justify-center items-center gap-[10px] ${name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || peopleCount === '' ? "opacity-30" : ""}`} disabled={name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || peopleCount === ''} onClick={(e) => { handleSubmit(e) }}>Book a Demo <FaArrowRightLong /></button>
                    }
                </div>
            </div>
        </div>
    )
}
