import React from 'react'

export default function DailyReport() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[245px] md:w-[300px] lg:w-[350px]'>Daily report generation :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Based on your task completion and engagement within the channel, AI will automatically generate your Daily reports once you check-out for the day. It helps the managers a clear view of what is the status of the projects. Conversely, automatic generation of reports can fully evade miscommunication.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>There is a seperate section for Daily reports in side bar. This can help to see year long data and helps in analysing the performance of the employee correctly.</p>
        </div>
    )
}
