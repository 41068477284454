import React from 'react'

export default function SoftwareToolsDoesTheProduct() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>What software tools does the product managers use to bring productivity in development of a product?</h2>
            <div className='flex flex-col gap-[25px]'>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>Mostly product managers use tools for </p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>1. Prototyping</p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>2. Roadmapping</p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>3. Task management</p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>4. Data management </p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>5. Communication </p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>6. User tracking and analysis </p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>7. Customer survey</p>
                <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>8. collaboration</p>
            </div>
        </div>
    )
}
