import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';



export default function Comparison() {
    const navigate = useNavigate();
    const list = [
        { title: 'Task segregation by Priority , Date , People', todoist: 'NO', workfast: 'YES' },
        { title: 'Task status , Tags , Priority', todoist: 'NO', workfast: 'YES' },
        { title: 'Invite Collaborators with restrictions', todoist: 'NO', workfast: 'YES' },
        { title: 'Add comments in task and subtasks', todoist: 'NO', workfast: 'YES' },
        { title: 'Drag and Drop file Attachments', todoist: 'NO', workfast: 'YES' },
        { title: 'Recurring tasks and remainder', todoist: 'YES', workfast: 'YES' },
        { title: 'Create complex Tasks and sub tasks', todoist: 'NO', workfast: 'YES' },
        { title: 'Chats and Direct messages', todoist: 'NO', workfast: 'YES' },
        { title: 'Channels and team messages', todoist: 'NO', workfast: 'YES' },
        { title: 'Adding Tasks from chat and channel directly', todoist: 'NO', workfast: 'YES' },
        { title: 'Share Tasks and sub tasks status link to anyone through other medium like emails , whatsapp etc', todoist: 'NO', workfast: 'YES' },
        { title: 'Real time notifications and alerts', todoist: 'YES', workfast: 'YES' },
        { title: 'Comment and Task history', todoist: 'NO', workfast: 'YES' },
        { title: 'Multiple sort options', todoist: 'NO', workfast: 'YES' },
        { title: 'Multiple Filter options', todoist: 'NO', workfast: 'YES' },
        { title: 'Separate folders for Inbox , Collaborator tasks etc.', todoist: 'NO', workfast: 'YES' },
        { title: 'Server edition', todoist: 'NO', workfast: 'YES' },
        { title: 'Link tasks and subtasks with projects', todoist: 'NO', workfast: 'YES' },
        { title: 'Get Daily , Weekly and Monthy Task analytics .', todoist: 'NO', workfast: 'YES' },
        { title: 'Download Task analytics as CSV', todoist: 'NO', workfast: 'YES' },
        { title: 'Mobile and web access', todoist: 'YES', workfast: 'YES' },
        { title: 'Set Assigner , Assignee', todoist: 'NO', workfast: 'YES' },
    ]
    return (
        <div className='flex flex-col justify-center items-center w-11/12 sm:w-10/12 mx-auto'>
            <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap gap-[8px] sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                <h3>We give more features than</h3>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/todoist.svg" alt='mondayIcon' className='w-[25px] sm:w-[35px] lg:w-[50px] xl:w-auto'/>
                <h3>Todoist</h3>
            </div>
            <h4 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal sm:w-10/12 lg:w-8/12 text-center mt-[22px]'>Easier task management, communication and collaboration made easy with Workfast.ai </h4>
            <div className='mt-[32px] w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full '>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center gap-[3px] sm:gap-[6px]'>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/todoist.svg" alt='Todoist' className='w-[13px] sm:w-[20px]'/>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Todoist</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center gap-[3px] sm:gap-[6px]'>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/workfastLogo.svg" alt='workfast' className='w-[15px] sm:w-auto'/>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.todoist === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.todoist === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold text-center'>Maximum productivity without compromise</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={() => {navigate('/signup')}}>Try it Free</button>
            </div>
        </div>
    )
}
