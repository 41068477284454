import React from 'react'

export default function Automation() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[308px] md:w-[385px] lg:w-[450px]'>Automation makes work easier:</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Do you want to get trigger at a specific stage in a project or task to see its result? just set tigger, set condition and response and leave it. you will be notified at right time in a specified channel.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Set recurring messages easily. need your team to ask daily report or work status every week. Don't worry. set a reminder for recurring message.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>similarly one can set reminder for reports, meetings, birthdays, announcements, salary credit etc easily. </p>
        </div>
    )
}
