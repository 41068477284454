import React from 'react'

export default function Emails() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[15px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[24px] lg:text-[30px] font-semibold relative z-[1] w-[80px] md:w-[90px] lg:w-[115px]'>Emails :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>How awesome it would be to view emails that matters most to you and your company. Gmail are flooded with promotional and distractive content.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai email will integrate your work email and segregate whats necessary to you the most. What if a mail which is most important to you has been buried inside the crowd?</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai will sense it and move it to focus section if you show interest in a mail. you can choose favourite mails, pin it and mark it important. if you need to ask a meeting with your lead or manager, you can set a meeting timer in the mail itself to know your availability for a review meeting.</p>
        </div>
    )
}
