const mode = process.env.REACT_APP_MODE ?? "LOCAL"; // LOCAL DEV STAGE UAT LIVE

const API = {};

API.HOST = "";
API.DETAILS = "";
API.BUTTON = "";
API.SENDOTP = "";
API.VERIFYOTP = "";
API.CREATEWORKSPACE = "";
API.CHANGEJOINSTATUS = "";
API.UPDATECOMPANYURL = "";
API.INVITEUSERTOWORKSPACE = "";
API.CREATECHANNEL = "";
API.INVESTORS = "";
API.RESELLER = "";

if (mode === "LOCAL") {
  API["HOST"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_mail.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["ONBOARDING"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["SENDOTP"] = "https://oneappstage.hifrds.com/api/v2/sendOTPAdminUser";

  API["VERIFYOTP"] = "https://oneappstage.hifrds.com/api/v2/verifyOTPAdminUser";

  API["CREATEWORKSPACE"] =
    "https://oneappstage.hifrds.com/api/v2/createWorkspace";

  API["CHANGEJOINSTATUS"] =
    "https://channelsocket.hifrds.com/api/v1/transfer/changeJoinStatus";

  API["UPDATECOMPANYURL"] =
    "https://oneappstage.hifrds.com/api/v2/updateCompanyUrl";

  API["INVITEUSERTOWORKSPACE"] =
    "https://oneappstage.hifrds.com/api/v2/inviteUsersToWorkspace";

  API["CREATECHANNEL"] = "https://oneappstage.hifrds.com/api/v2/createChannel";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["SPECIAL_INITIATE_PAYMENT"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialInitiatePayment";

  API["UPDATE_ORGANIZATION_INFO"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialUpdateOrganizationInfo";

  API["GET_SPECIAL_OFFER"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/getSpecialOffer";

  API["PAYMENT_SUCCESS"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialPaymentSuccess";

  API["UPDATE_WORKSPACE_PAYMENT"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/updateWorkspacePayment";

  API["GET_OFFER_DETAILS"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/getOfferDetails";
}

if (mode === "DEV") {
  API["HOST"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/dev/register_mail.php";

  API["BUTTON"] = "https://oneappbackend.hifrnds.in/api/v1/website/addLog";

  API["SENDOTP"] = "https://oneappstage.hifrds.com/api/v2/sendOTPAdminUser";

  API["VERIFYOTP"] = "https://oneappstage.hifrds.com/api/v2/verifyOTPAdminUser";

  API["CREATEWORKSPACE"] =
    "https://oneappstage.hifrds.com/api/v2/createWorkspace";

  API["CHANGEJOINSTATUS"] =
    "https://channelsocket.hifrds.com/api/v1/transfer/changeJoinStatus";

  API["UPDATECOMPANYURL"] =
    "https://oneappstage.hifrds.com/api/v2/updateCompanyUrl";

  API["INVITEUSERTOWORKSPACE"] =
    "https://oneappstage.hifrds.com/api/v2/inviteUsersToWorkspace";

  API["CREATECHANNEL"] = "https://oneappstage.hifrds.com/api/v2/createChannel";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["SPECIAL_INITIATE_PAYMENT"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialInitiatePayment";

  API["UPDATE_ORGANIZATION_INFO"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialUpdateOrganizationInfo";

  API["GET_SPECIAL_OFFER"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/getSpecialOffer";

  API["PAYMENT_SUCCESS"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/specialPaymentSuccess";

  API["UPDATE_WORKSPACE_PAYMENT"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/updateWorkspacePayment";

  API["GET_OFFER_DETAILS"] =
    "https://oneappbackend.hifrnds.in/api/v1/adminDashboard/getOfferDetails";
}

if (mode === "STAGE") {
  API["HOST"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/register_mail.php";

  API["DETAILS"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/uat/personal_assistence_details.php";

  API["BUTTON"] = "https://onebackenduat.hifrnds.in/api/v1/website/addLog";

  API["SENDOTP"] = "https://oneappuat.hifrnds.in/api/v2/sendOTPAdminUser";

  API["VERIFYOTP"] = "https://oneappuat.hifrnds.in/api/v2/verifyOTPAdminUser";

  API["CREATEWORKSPACE"] =
    "https://oneappuat.hifrnds.in/api/v2/createWorkspace";

  API["CHANGEJOINSTATUS"] =
    "https://channeluat.hifrnds.in/api/v1/transfer/changeJoinStatus";

  API["UPDATECOMPANYURL"] =
    "https://oneappuat.hifrnds.in/api/v2/updateCompanyUrl";

  API["INVITEUSERTOWORKSPACE"] =
    "https://oneappuat.hifrnds.in/api/v2/inviteUsersToWorkspace";

  API["CREATECHANNEL"] = "https://oneappuat.hifrnds.in/api/v2/createChannel";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["SPECIAL_INITIATE_PAYMENT"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/specialInitiatePayment";

  API["UPDATE_ORGANIZATION_INFO"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/specialUpdateOrganizationInfo";

  API["GET_SPECIAL_OFFER"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/getSpecialOffer";

  API["PAYMENT_SUCCESS"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/specialPaymentSuccess";

  API["UPDATE_WORKSPACE_PAYMENT"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/updateWorkspacePayment";

  API["GET_OFFER_DETAILS"] =
    "https://onebackenduat.hifrnds.in/api/v1/adminDashboard/getOfferDetails";
}

if (mode === "UAT") {
  API["HOST"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/register_mail.php";

  API["BUTTON"] = "https://onebackendstage.hifrnds.in/api/v1/website/addLog";

  API["SENDOTP"] = "https://oneappstage.hifrds.com/v2/api/v2/sendOTPAdminUser";

  API["VERIFYOTP"] =
    "https://oneappstage.hifrds.com/v2/api/v2/verifyOTPAdminUser";

  API["CREATEWORKSPACE"] =
    "https://oneappstage.hifrds.com/v2/api/v2/createWorkspace";

  API["CHANGEJOINSTATUS"] =
    "https://stagesocket.hifrds.com/api/v1/transfer/changeJoinStatus";

  API["UPDATECOMPANYURL"] =
    "https://oneappstage.hifrds.com/v2/api/v2/updateCompanyUrl";

  API["INVITEUSERTOWORKSPACE"] =
    "https://oneappstage.hifrds.com/v2/api/v2/inviteUsersToWorkspace";

  API["CREATECHANNEL"] =
    "https://oneappstage.hifrds.com/v2/api/v2/createChannel";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["SPECIAL_INITIATE_PAYMENT"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/specialInitiatePayment";

  API["UPDATE_ORGANIZATION_INFO"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/specialUpdateOrganizationInfo";

  API["GET_SPECIAL_OFFER"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/getSpecialOffer";

  API["PAYMENT_SUCCESS"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/specialPaymentSuccess";

  API["UPDATE_WORKSPACE_PAYMENT"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/updateWorkspacePayment";

  API["GET_OFFER_DETAILS"] =
    "https://onebackendstage.hifrnds.in/api/v1/adminDashboard/getOfferDetails";
}

if (mode === "LIVE") {
  API["HOST"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/register_mail.php";

  API["DETAILS"] =
    "https://devarus.aftergen.in/dev/API/oneAppWorkspace/live/personal_assistence_details.php";

  API["BUTTON"] = "https://k8s.onlyapp.in/node/api/v1/website/addLog";

  API["SENDOTP"] = "https://k8s.onlyapp.in/oneapp/v2/api/v2/sendOTPAdminUser";

  API["VERIFYOTP"] =
    "https://k8s.onlyapp.in/oneapp/v2/api/v2/verifyOTPAdminUser";

  API["CREATEWORKSPACE"] =
    "https://k8s.onlyapp.in/oneapp/v2/api/v2/createWorkspace";

  API["CHANGEJOINSTATUS"] =
    "https://k8s.onlyapp.in/channel/api/v1/transfer/changeJoinStatus";

  API["UPDATECOMPANYURL"] =
    "https://k8s.onlyapp.in/oneapp/v2/api/v2/updateCompanyUrl";

  API["INVITEUSERTOWORKSPACE"] =
    "https://k8s.onlyapp.in/oneapp/v2/api/v2/inviteUsersToWorkspace";

  API["CREATECHANNEL"] =
    "https://k8s.onlyapp.in/oneapp/v2/api/v2/createChannel";

  API["INVESTORS"] = "https://backend.hifrds.com/pepul/addInvestorWorkfast.php";

  API["RESELLER"] = "https://backend.hifrds.com/pepul/reseller.php";

  API["SPECIAL_INITIATE_PAYMENT"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/specialInitiatePayment";

  API["UPDATE_ORGANIZATION_INFO"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/specialUpdateOrganizationInfo";

  API["GET_SPECIAL_OFFER"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/getSpecialOffer";

  API["PAYMENT_SUCCESS"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/specialPaymentSuccess";

  API["UPDATE_WORKSPACE_PAYMENT"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/updateWorkspacePayment";

  API["GET_OFFER_DETAILS"] =
    "https://k8s.onlyapp.in/node/api/v1/adminDashboard/getOfferDetails";
}

module.exports = API;
