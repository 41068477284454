import React from 'react'

export default function RolesOfProductManager() {
  return (
    <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto'>
        <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent font-bold relative z-[1]'>Major roles of product managers :</h2>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[20px] md:mt-[40px] md:w-10/12 md:mx-auto'>1. Conducting market research , competitor analysis and customer research</p>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] w-full md:w-10/12 md:mx-auto text-start'>2. Creating a product roadmap of releases in stages.</p>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] md:w-10/12 md:mx-auto'>3. Coordinating among design, engineering, and marketing teams</p>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] md:w-10/12 md:mx-auto'>4. Owning the Daily Scrum and Sprint Planning meetings to bring the right outcome.</p>
        <p className='text-[#E4E8EB] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] md:w-10/12 md:mx-auto'>5. Defining and analyzing product metrics and analytics</p>
    </div>
  )
}