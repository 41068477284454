import React from 'react'

export default function AllInOne() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[28px] lg:text-[30px] font-semibold relative z-[1] w-[210px] sm:w-[280px] lg:w-[300px]'>All-in-one workbase :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>In Workfast.ai, the user can create a channel dedicated to particular project. similarly they can chat privately in messaging platform called chat. This give added advantage to teams for easier and faster communication in the project.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>one plus point is, channel has different templates to make the work easier. For example, Teams can use Approval template for getting any approvals from their managers whom they tag in group. Except the approver no one can see the message.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>similarly in Feedback template the team members can share feedbacks of current projects.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Other exciting feature is adding Messages into task/chat to task. if any message is really important, the members can assign it as a task without going out of chat box.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The members can check-in or check-out within the channel itself. This ensures the availability of the members for work. once a member is checked-out, daily report is automatically generated and added to Daily reports column.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Every sections in the platform are interconnected. everything has a meaning. All have been created to make the platform compatible for productivity and management.</p>
        </div>
    )
}
