import React from 'react'

export default function EmailCalender() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[225px] sm:w-[275px] lg:w-[315px]'>Email - Calendar Duo :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Making email segregation easy within the platform. Do you want certain emails to be in a focus mode? Its easy. The Email which is often replied and engaged will automatically put to focus mode. No advertisements and promotions or social feeds clog your inbox. Mark important mails, save and pin it. one can easily set meeting within the email with a Timer and request available timing for meeting from other side. AI will work quickly and let the sender know, the available time of the receiver for a meeting.</p>
        </div>
    )
}
