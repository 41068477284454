import React from 'react'

export default function LookingForANewPlatform() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Hey Peter, I am really getting tired of using multiple tools for handling my projects. As a project manager its frustrating to constantly switch between platforms in order to bring together various tasks into one location. I use almost all software tools, but still miscommunications and project mismanagement occurs consistently.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Uffff… Come on James!!!, Its all becomes easy if you start to use Workfast.ai. There are many project managers like James, who constantly getting frustrated using multiple tools and not keep tracking of the tasks and projects. If you are one of James kind, Workfast.ai will be the best choice for your project management.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>In this article I am going to walk you through what is Workfast.ai, How it will impact your work life and How it will enhance productivity .</p>
        </div>
    )
}
