import React from 'react'

export default function Principles() {
    const points = [
        {number: "01", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_1.svg`, title: "You grow , we grow", description: "We believe in benefiting our partners , thus we get benefited eventually . When you succeed, we all succeed. Your success helps us attract more customers."},
        {number: "02", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_2.svg`, title: "Probity", description: "Maintain transparency and honesty in all dealings, ensuring clear and truthful communication about the product and any potential conflicts of interest."},
        {number: "03", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_3.svg`, title: "Integrity", description: "Consistently act with fairness and reliability, honouring commitments and upholding ethical standards in every interaction."},
        {number: "04", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_4.svg`, title: "Customer- Centric Focus ", description: "Focus on what customers need and want. We both should provide value and solve customer problems."},
        {number: "05", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_5.svg`, title: "Transparency", description: "Maintain open and honest communication about goals, expectations, and performance metrics. Transparency builds trust and ensures that our partnerships are aligned and informed."},
        {number: "06", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_6.svg`, title: "Accountability", description: "Own your actions and results. We both should meet our commitments and address any problems quickly."},
        {number: "07", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_7.svg`, title: "Long Term Relationship", description: "We both should Invest in building relationship for long-term success rather than just short-term gains."},
        {number: "08", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_8.svg`, title: "Commitment to quality", description: "We both should uphold high standards for the product and service. we should be committed to representing Workfast.ai accurately and delivering a quality experience to customers."},
        {number: "09", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_9.svg`, title: "Mutual Respect", description: "Treat each other with respect. Value each other’s contributions and viewpoints to build a positive working relationship."},
        {number: "10", image: `https://dz1x1c630cl14.cloudfront.net/webassets/bg_10.svg`, title: "Clear Expectations", description: "Define and agree on goals and responsibilities from the start. This helps prevent misunderstandings and ensures everyone knows what is expected."},
    ]
  return (
    <div className='flex flex-col justify-center w-full px-[20px] sm:px-0 sm:w-10/12 xl:w-9/12 mx-auto items-center gap-[22px] sm:gap-[32px] lg:gap-[42px]' id='principles'>
        <h3 className='text-[#181F38] font-bold text-[24px] sm:text-[32px] lg:text-[42px] xl:text-[52px] text-center'>10 values and principles we follow and expect our partners to abide </h3>
        <div className='flex flex-col gap-[12px] sm:gap-[22px] lg:gap-[32px] justify-start items-start'>
            {points.map((item, index) => (
                <div key={index} className='flex gap-[18px] sm:gap-[28px]'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <img src={item.image} alt='item_image' className='absolute left-0 z-[0]'/>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{item.number}</div>
                    </div>
                    <div>
                        <h3 className='text-[#181F38] text-[18px] sm:text-[22px] lg:text-[28px] font-bold'>{item.title}</h3>
                        <h4 className='text-[#2B3857] text-[12px] sm:text-[14px] lg:text-[18px] font-normal mt-[4px] sm:mt-[8px]'>{item.description}</h4>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}
