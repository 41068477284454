import React from 'react'

export default function MajorRolesOfProductManagers() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[30px] xl:gap-[50px]'>
            <h2 className='bg-gradient-to-r from-[#FFFFFF] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[32px] xl:text-[45px] font-semibold relative z-[1]'>Major roles of product managers :</h2>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>1. Conducting market research , competitor analysis and customer research </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>2. Setting a product vision and strategy clearly for the product.</p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>3. Creating a product roadmap of releases in stages. </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>4. Coordinating among design, engineering, and marketing teams </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>5. Owning the Daily Scrum and Sprint Planning meetings to bring the right outcome. </p>
            <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[21px] xl:text-[26px] font-normal'>6. Defining and analyzing product metrics and analytics</p>
        </div>
    )
}
