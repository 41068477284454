import React from 'react'

export default function Principles() {
    return (
        <div className="h-full w-full flex flex-col items-center relative mb-[20px] lg:mb-[60px] xl:mb-[100px] overflow-hidden px-[20px] sm:px-[40px] gap-[10px]">
            <h2 className='text-[24px]  sm:text-[40px] xl:text-[60px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent font-semibold text-center'>Ten Principles of Workfast.ai For Investments</h2>
            <ol className='list-decimal ml-[20px] sm:ml-[40px] flex flex-col gap-[10px]'>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We have a clear vision of creating great values for the people along the way as a byproduct we will be making a billion-dollar company.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We will not change the culture, people who trusted us in the early days, Culture is our core strength and we will run the way as we are and scale exponentially without losing its value system.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We are not just looking for money here, we look for long-term partners who respect & value the vision and also strategically help us grow. If you are just looking to put money and double or make it 10x then we may not be the right partner for you.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We don't believe in mentors as people can't give quick solutions without knowing the contexts hence we don't give free equity for mentors or brand ambassadors.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We have to mutually respect, give space and freedom to each other for the benefit of the bigger purpose, and respect decisions taken in the situations.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We believe in People, we value trust and word rather than signed papers.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We are open for suggestions, ideas told with the right intentions, You can suggest good things and we are open but decisions are always ours.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>Peace of mind is important. End of the day, we have to happily sleep, so let's happily travel towards the vision and trust each other. It's a long-term relationship.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We will provide quarterly updates and other key important updates. But just because you are an investor, you can't involve in our day-to-day operations and decisions.</li>
                <li className='text-[#FFFFFF] text-[14px]  sm:text-[20px] xl:text-[26px] font-medium'>We respect your time and we expect the same.</li>
            </ol>
        </div>
    )
}
