import { create } from "zustand";

const paymentStore = create((set) => ({
  paymentStatus: "",
  userImage: "",
  workspaceName: "",
  userEmail: "",
  userName: "",
  designation: "",
  signupType: "",
  selectedPlan: "",
  deviceId: "",
  userDetails: "",
  workspaceDetails: "",
  orderCreationId: "",
  infoId: "",
  setPaymentStatus: (paymentStatus) => set({ paymentStatus: paymentStatus }),
  setUserImage: (userImage) => set({ userImage: userImage }),
  setWorkspaceName: (workspaceName) => set({ workspaceName: workspaceName }),
  setUserEmail: (userEmail) => set({ userEmail: userEmail }),
  setUserName: (userName) => set({ userName: userName }),
  setDesignation: (designation) => set({ designation: designation }),
  setSignupType: (signupType) => set({ signupType: signupType }),
  setSelectedPlan: (selectedPlan) => set({ selectedPlan: selectedPlan }),
  setDeviceId: (deviceId) => set({ deviceId: deviceId }),
  setUserDetails: (userDetails) => set({ userDetails: userDetails }),
  setWorkspaceDetails: (workspaceDetails) =>
    set({ workspaceDetails: workspaceDetails }),
  setOrderCreationId: (orderCreationId) =>
    set({ orderCreationId: orderCreationId }),
  setInfoId: (infoId) => set({ infoId: infoId }),
}));

export default paymentStore;
