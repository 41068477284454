import React from 'react'
import channel from "../../../../../images/channel_blog.png";
import project from "../../../../../images/project_blog.png";
import task from "../../../../../images/task_blog.png";
import chat from "../../../../../images/chat_blog.png";
import search from "../../../../../images/search_blog.png";
import notification from "../../../../../images/notification_blog.png";



export default function CoreFeaturesOfWorkfastAi() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='bg-gradient-to-r from-[#FFF2B9] to-[#FDD220] bg-clip-text text-transparent text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[290px] md:w-[355px] lg:w-[410px]'>Core features of Workfast.ai :</h2>
            <div className='flex flex-col gap-[10px] lg:gap-[18px] xl:gap-[18px]'>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>1.Channel :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>A channel is a space or stream for communication where members of an organisation or a company can form groups among themselves for specific tasks, work, goals. A channel is created for a purpose by the heads of team or a manager or person who is responsible for the work to be done. A channel may also include all members of a company to announce important events, occasions or messages.</p>
                    <img src={channel} alt=''/>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>2.Projects :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Project is a space for team members to create a workflow for a project. Some may name it as project, some may call it customers, clients, Accounts or any name of their choice. While creating a project one can choose already set templates. For example Product development, Agile project management, Kanban software development, sales template etc. Once selected, a new project is created. Project description, status, tags and priority can be set while creating a project. User can Create a workflow needed to complete a project. One can make changes to workflow whenever needed. Project dashboard will give a detailed analysis of overall task done, weekly analytics etc. A user can view projects in different aspects. For example Workfast.ai provides 5 options for viewing projects.</p>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>They are Group view, Stage view, People view, Date view, Priority view.</p>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Group view : </h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>If a user want to see overall tasks assigned in a project, Group view is the best option. This view gives a detailed aspect of one project in minute detail.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Stage view:</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>A project owner or a manager would like to view their Tasks progress in which stages, then this stage view will help to catch the movement of a project.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>People view:</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>People view informs which team members are allocated which tasks in a project. This helps to know whether a employees is overburdened with work or with less work by a manager or Leads.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Date view:</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>A team would love to know what’s their task of the day rather than knowing which stage does the project is presently. So Team members can see what’s their todays list of task that needed to be done.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Priority view : </h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Priority will often change in project. Certain tasks which was earlier considered moderate would have change to High priority. So if one need to know which tasks in which priority it would help teams to adjust to the need easily.</p>
                    </div>
                    <img src={project} alt=''/>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>3.Tasks :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Task is one feature which helps the users to manage professional and personal tasks. Assign a task to your team member, set a due date and time. That’s all you have to do. It is as simple as writing on a paper. You can any task to anyone including your personal task and set reminder. On top of it, set priority, describe the task and give a title.</p>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The user can see the overall task in Date, People, Priority and Project view.</p>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Date view :</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>View the tasks assigned on date wise. This helps to know what tasks are assigned on a particular date.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>People View :</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The user, usually managers can view the tasks assigned and in progress on particular member basis. For example one can see what tasks assigned to Sebastin.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Priority view :</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Viewing tasks on the basis of Priority I.e. High, medium, low priority.</p>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h2 className='text-[#E4E8EB] text-[17px] md:text-[20px] lg:text-[22px] font-semibold relative z-[1]'>Project view :</h2>
                        <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The user can view the task on the basis of project which are currently running.</p>
                    </div>
                    <img src={task} alt=''/>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>4.Intelligence :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>As the name suggest, Workfast.ai is an intelligent platform. It will give any information related to your search. If you want to know what’s your next weeks priority list. It will retrieve and form a priority list for you. As a manager you want to know who is on leave, what projects does Sebastian working on, how many members are in leave today in your team, is there any pending approval awaiting your response or anything you want, Workfast.ai will satisfy your informational need. This will increase your productivity and decreases dependency on others. Thus productivity jumps to a new level.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>5.Chat:</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>It is an informal messaging platform where one can Dm your team members and even form group chat. You can send messages to yourself as well. One can send all kinds of data. One speciality is, a conversational text can be added to task and can be assigned to anyone without leaving the chat box. This will help not to miss anything when we chat.</p>
                    <img src={chat} alt=''/>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>6.Personalisation :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Not everyone in a company is a manager or CEO. Everything is based on hierarchy. Similarly not everyone need all information. So We allow users to customise their Launch Bar or bottom navigation bar for their comfortability. A CEO wants calendar as his first choice, intelligence as his second choice, projects as third and Goals as fourth. That would be great. As an employee, one need to know what’s the task today, what projects are am I part of, channel to communicate. This becomes so easy, which will reduce the distraction from other unwanted menus. Similarly one can switch off the unrelated modules and menus with just one click.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>7.Integration :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Most users have been conditioned or confirmed for certain tools. I am running everything in excel itself!!!, It’s difficult for me migrate to new app. I am using multiple tools to collaborate, yet I couldn’t get a sync with my team. Don’t worry, you can integrate as many tools possible. If your design team uses Figma, best choice is to integrate and work. You can integrate any tools to Workfast.ai for a smooth flow in running your organisation.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>8.Search :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>There will be a universal search bar option which a user can search anything, for example documents, names, tags, projects, videos, audios, reports etc. This option satisfies our vision of “not letting the user to go out of the platform for unnecessary reason”.</p>
                    <img src={search} alt=''/>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#FFF2B9] text-[18px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>9.Notification :</h2>
                    <p className='text-[#E4E8EB] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>If a member is tagged in a channel or assigned any tasks , he/she will be notified in real time. This helps user not to miss out any important updates.</p>
                    <img src={notification} alt=''/>
                </div>
            </div>
        </div>
    )
}
